import { promotionsWorkers } from '../../services/http';
import { sanitizePromoData, setPromoStatus } from '../../utils/promotions';
import { findPromoGamesList } from '@tlf-e/brand-utils';
import { PROMO_STATUS_ONGOING, PROMO_STATUS_UPCOMING } from '../../variables';

const { promotionsRequest } = promotionsWorkers;

const GET_PROMOTIONS = 'GET_PROMOTIONS';
const GET_PROMOTIONS_ERROR = 'GET_PROMOTIONS_ERROR';
const GET_PROMOTIONS_LOADING = 'GET_PROMOTIONS_LOADING';
const SET_PROMOTION_TO_BE_UPDATED = 'SET_PROMOTION_TO_BE_UPDATED';

export const initialState = {
    isLoading: false,
    isLoaded: false,
    allPromotions: [],
    isError: false,
    promotionToBeUpdated: null
};

const promotions = (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_PROMOTIONS:
        return {
            allPromotions: action.payload,
            isLoading: false,
            isError: false,
            isLoaded: true
        };
    case GET_PROMOTIONS_ERROR:
        return {
            ...state,
            isLoading: false,
            isError: true,
            isLoaded: true
        };
    case GET_PROMOTIONS_LOADING:
        return {
            ...state,
            isLoading: true,
            isError: false
        };
    case SET_PROMOTION_TO_BE_UPDATED:
        return {
            ...state,
            promotionToBeUpdated: action.payload
        };
    default:
        return state;
    }
};

export default promotions;

const getPromotions = (payload) => ({
    type: GET_PROMOTIONS,
    payload
});

export const getPromotionContent = (lang) => {
    const fail = (payload) => ({
        type: GET_PROMOTIONS_ERROR,
        payload
    });
    const loading = () => ({
        type: GET_PROMOTIONS_LOADING
    });
    return (dispatch, getState) => {
        dispatch(loading());
        promotionsRequest(lang)
            .then((res) => {
                const promotions = res.data.data.map((promo) => {
                    const promoStatus = setPromoStatus(promo.dateTimeStart, promo.dateTimeEnd);
                    const promoGames = promo.games.filter((gameId) => gameId !== null);
                    const gamesList = findPromoGamesList(promoGames, getState().games.data.games);

                    return {
                        ...promo,
                        ...promoStatus,
                        slug: promo.title.toLowerCase().replace(/\s/g, '-'),
                        background: promo.backgroundImage,
                        backgroundWEBP: promo.backgroundImageWEBP,
                        bigBackground: promo.backgroundImage,
                        bigBackgroundWEBP: promo.backgroundImageWEBP,
                        description: sanitizePromoData(promo.description),
                        howToParticipate: sanitizePromoData(promo.howToParticipate),
                        termsAndConditions: sanitizePromoData(promo.termsAndConditions),
                        games: gamesList,
                        tags: promo.tags.split(',').map((item) => item.trim()),
                        displayOnLobby: true
                    };
                });

                const sortedPromotions = [...promotions].sort((a, b) => {
                    // Define the order of statuses
                    const statusOrder = {
                        [PROMO_STATUS_ONGOING]: 0,
                        [PROMO_STATUS_UPCOMING]: 1
                    };

                    const orderA = statusOrder[a.status];
                    const orderB = statusOrder[b.status];

                    if (orderA !== undefined && orderB !== undefined) {
                        return orderA - orderB;
                    } else if (orderA !== undefined) {
                        return -1;
                    } else if (orderB !== undefined) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                dispatch(updatePromotionsList(sortedPromotions));
            })
            .catch(() => dispatch(fail()));
    };
};

export const setPromotionToBeUpdated = (payload) => ({
    type: SET_PROMOTION_TO_BE_UPDATED,
    payload
});

export const updatePromotionsList = (promotions) => {
    return (dispatch) => {
        const [promotionToBeChanged] = [...promotions]
            .filter((item) => item.timerTimestamp)
            .sort((a, b) => a.timerTimestamp - b.timerTimestamp);

        dispatch(getPromotions(promotions));
        dispatch(setPromotionToBeUpdated(promotionToBeChanged || null));
    };
};
