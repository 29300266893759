import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import { initSubmitNewPassword, newPassword } from '../../../store/reducers/user';
import { Form, Formik } from 'formik';
import { handleCloseMenu } from '../../../utils/locationConfig';
import PasswordField from '../password';

const NewPassword = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { isLoading } = useSelector((state) => state.user.submitNewPassword);
    const dispatch = useDispatch();
    const router = useRouter();
    const resetToken = router.query.token;

    useEffect(() => {
        dispatch(initSubmitNewPassword());
    }, []);

    const validationSchema = yup.object({
        newPassword: yup
            .string()
            .required(tr.new_password_required)
            .min(8, tr.new_password_required_min)
            .max(255, tr.new_password_required_max)
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;'\x22<,>.?/])[^\sа-яА-ЯёЁ±№§]*$/,
                tr.new_password_adviced_password
            ),
        confirmPassword: yup
            .string()
            .required(tr.new_password_confirm_password_required)
            .oneOf([yup.ref('newPassword'), null], tr.new_password_dont_match)
    });

    return (
        <Formik
            initialValues={{
                newPassword: '',
                confirmPassword: ''
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={(values) =>
                dispatch(
                    newPassword({
                        password: values.newPassword,
                        token: resetToken
                    })
                )
            }
        >
            {() => (
                <div className="form-container">
                    <div className="form--title">{tr['new_password_header']}</div>
                    <Form className="login-form">
                        <PasswordField fieldName="newPassword" title={tr['reset.form.password.new']} testId="new_password" />
                        <PasswordField
                            fieldName="confirmPassword"
                            title={tr['new_password_confirmation']}
                            testId="confirm_password"
                        />
                        <div className="form--btns">
                            <button
                                className="btn btn-cancel btn-modern"
                                onClick={() => handleCloseMenu()}
                                data-testid="cancel_new_password_btn"
                                type="button"
                            >
                                {tr['btn.cancel']}
                            </button>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="btn btn-submit btn-modern"
                                data-testid="submit_new_password"
                            >
                                {isLoading ? tr['global.loading'] : tr['new_password_save_btn']}
                            </button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default NewPassword;
