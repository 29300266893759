import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_SERVICE_NAME } from '../variables';

// export const datadogInit = (userAgent) => {
//     if (process.env.NODE_ENV &&
//         process.env.NODE_ENV === 'production' &&
//         process.env.NEXT_PUBLIC_API_URL &&
//         !process.env.NEXT_PUBLIC_API_URL.includes('staging.tucuxi.io'))  {
//
//         // eslint-disable-next-line max-len
//         const botPattern = '(slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
//         const regex = new RegExp(botPattern, 'i');
//         const conditionalSampleRate = regex.test(userAgent) ? 0 : 35;
//
//         datadogRum.init({
//             applicationId: '3dcf6713-aab4-48a0-b848-e29639e08f7f',
//             clientToken: 'pub207a21c9cb46cc450241275a934f67e8',
//             site: 'datadoghq.eu',
//             service: DATADOG_SERVICE_NAME,
//             sessionSampleRate: conditionalSampleRate,
//             sessionReplaySampleRate: 100,
//             trackUserInteractions: true,
//             trackFrustrations: true,
//             trackResources: true,
//             trackLongTasks: true,
//             defaultPrivacyLevel: 'mask-user-input',
//             enableExperimentalFeatures: ['clickmap']
//         });
//         datadogRum.startSessionReplayRecording();
//     }
// };

export const datadogAddError = (message) => {
    // Check if Datadog is well-configured
    if (datadogRum.getInitConfiguration()?.service === DATADOG_SERVICE_NAME) {
        datadogRum.addError(new Error(message));
    }
};
