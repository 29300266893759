const Success = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clipPath="url(#clip0_107_15681)">
            <path
                opacity="0.2"
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="white"
            />
            <path d="M22.4016 11.2L12.5554 20.8L9.60156 17.9228" fill="#D73210" />
            <path
                d="M22.4016 11.2L12.5554 20.8L9.60156 17.9228"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_107_15681">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Success;
