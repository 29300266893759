import { combineReducers } from 'redux';
import global from './global';
import games from './games';
import user from './user';
import register from './register';
import slider from './slider';
import bank from './bank';
import bonus from './bonus';
import promotions from './promotions';
import prefooter from './prefooter';
import captainUp from './captainUp';
import helpCenter from './helpCenter';

export default combineReducers({
    global,
    games,
    user,
    register,
    slider,
    bank,
    bonus,
    promotions,
    prefooter,
    captainUp,
    helpCenter
});
