import XRegExp from 'xregexp';
import dayjs from 'dayjs';
import { PROD_HOSTNAME } from '../variables';

export const addThousandsSeparator = (value) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const inputCurrencyAmountFormatter = (amount, currency) => {
    if (currency === 'JPY') {
        return addThousandsSeparator(amount);
    }

    return amount;
};

export const setAmountValue = (amount, currency, { setFieldValue, setQueryValue }) => {
    const cleanedValue = amount.replace(/,/g, '').replace(/[^\d.]/g, '');
    let value = '';
    if (cleanedValue.includes('.')) {
        const parts = cleanedValue.split('.');
        let combined = parts.slice(0, -1).join('');
        const lastPart = parts[parts.length - 1];
        value = `${combined}.${lastPart.slice(0, 2)}`;
    } else {
        value = cleanedValue;
    }

    const formatedValue = inputCurrencyAmountFormatter(value, currency);
    setFieldValue('amount', formatedValue);
    setQueryValue && setQueryValue(formatedValue);
};

export const allowSpecialRegex = new XRegExp(
    '^[\\p{L} \\p{M}àâäèéêëîïôöœùûüÿçÀÂÄÈÉÊËÎÏÔÖŒÙÛÜŸÇ\']+(?:[\\s-][\\p{L} \\p{M}àâäèéêëîïôöœùûüÿçÀÂÄÈÉÊËÎÏÔÖŒÙÛÜŸÇ\']+)*$'
);

export const formatSeconds = (seconds) => {
    const duration = dayjs.duration(seconds, 'seconds');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const secondsFormatted = duration.seconds().toString().padStart(2, '0');
    return `${minutes}:${secondsFormatted}`;
};

export const getHostName = (headers) => {
    const ssrHostName = `https://${headers['x-forwarded-host']}`;
    const hostName = headers['x-forwarded-host'] ? ssrHostName : PROD_HOSTNAME;
    return hostName;
};

export const getIsJapanese = (character) => {
    // Regular expression to match Japanese characters (Hiragana, Katakana, Kanji)
    const japaneseRegex = /[\u3000-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u3400-\u4DBF]/;
    return japaneseRegex.test(character);
};
