import { initSubmitLoginUser, mfaLoginUser, mfaStatusInit, setMfaLogin } from '../../../../store/reducers/user';
import { MODAL_STATUS_ERROR, TOTP_CODE_INVALID } from '../../../../variables';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Form, Formik } from 'formik';
import { Fragment, useEffect } from 'react';
import MfaStatusContent from '../../profile/mfa/MfaStatusContent';
import MfaVerificationCode from '../../MfaVerificationCode';
import Heading from '../../Heading';

const MfaForm = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { isError } = useSelector((state) => state.user.mfaEnableStatuses);
    const { isLoading } = useSelector((state) => state.user.submitLogin);
    const mfaCodeBoxSize = isMobile ? 50 : 60;
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(mfaStatusInit());
            dispatch(initSubmitLoginUser());
        };
    }, []);

    const onStepBackClick = () => {
        dispatch(setMfaLogin(false));
        dispatch(mfaStatusInit());
    };

    const contactSupport = () => {
        if (window.zE && typeof window.zE === 'function') {
            window.zE('webWidget', 'open');
        } else {
            onStepBackClick();
        }
    };

    const initialValues = {
        mfaCode: ''
    };

    return (
        <div className="form-container" data-testid="mfa_form">
            {isError.message !== TOTP_CODE_INVALID && isError.status ? (
                <MfaStatusContent
                    status={MODAL_STATUS_ERROR}
                    title={tr['mfa.error.status.modal.title']}
                    content={tr['mfa.error.status.modal.content']}
                    loginStatus
                />
            ) : (
                <Fragment>
                    <div className="form--title">{tr['mfa.form.title']}</div>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            dispatch(mfaLoginUser(values.mfaCode));
                            resetForm();
                        }}
                    >
                        {({ values }) => {
                            return (
                                <Form id="mfa-authenticator">
                                    <MfaVerificationCode
                                        title={tr['mfa.form.authenticator.title']}
                                        mfaCodeWidth={mfaCodeBoxSize}
                                        mfaCodeHeight={mfaCodeBoxSize}
                                    />
                                    <div className="mfa-support" onClick={contactSupport}>
                                        <span>{tr['mfa.form.support.text']}</span>
                                        <Heading heading="h5">{tr['mfa.form.support.link']}</Heading>
                                    </div>
                                    <div className="form--btns">
                                        <button
                                            className="btn btn-cancel btn-modern"
                                            onClick={onStepBackClick}
                                            data-testid="cancel_mfa"
                                            type="button"
                                        >
                                            {tr['btn.cancel']}
                                        </button>
                                        <button
                                            className="btn btn-submit btn-modern"
                                            data-testid="submit_mfa"
                                            type="submit"
                                            disabled={values.mfaCode.length !== 6 || isLoading}
                                        >
                                            {tr['submit.code.btn']}
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Fragment>
            )}
        </div>
    );
};

export default MfaForm;
