import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Drawer from '@mui/material/Drawer';
import Menu from '../common/Menu';
import NavMenu from '../common/nav-menu';
import Login from '../common/login';
import ForgotPassword from '../common/forgot-password';
import ForgotPasswordResetSuccessModal from '../common/forgot-password/fragments/ForgotPasswordResetSuccessModal';
import { handleCloseMenu } from '../../utils/locationConfig';
import { setMFAEnabled, setMfaToken } from '../../store/reducers/user';
import { MENU_FORGOT_PASSWORD, MENU_GENERAL, MENU_LOGIN, MENU_NEW_PASSWORD, MENU_REGISTER } from '../../variables';
import RegisterNew from '../common/register-new';
import NewPassword from '../common/new-password';
import NewPasswordSuccessModal from '../common/new-password/fragments/NewPasswordSuccessModal';
import NewPasswordExpiredLinkModal from '../common/new-password/fragments/NewPasswordExpiredLinkModal';
import ActiveSelfExcludedWarningModal from '../common/ActiveSelfExcludedWarningModal';

const menuComponents = {
    [MENU_FORGOT_PASSWORD]: <ForgotPassword />,
    [MENU_GENERAL]: <NavMenu />,
    [MENU_LOGIN]: <Login />,
    [MENU_NEW_PASSWORD]: <NewPassword />,
    [MENU_REGISTER]: <RegisterNew />
};

//HOC for <Root /> to prevent unexpected behavior of Drawer on changing page location
export const withSideMenu = (PageComponent) => {
    const WithSideMenu = ({ ...props }) => {
        const isAuth = useSelector((state) => state.user.isAuth);
        const loadingProgress = useSelector((state) => state.global.loadingProgress);
        const router = useRouter();
        const tokenRegex = new RegExp(`[?&]token=[^&?]+`);
        const isNewPassAvailable = tokenRegex.test(router.asPath);
        const onClose = () => handleCloseMenu();
        const componentsConfigs = {
            [MENU_FORGOT_PASSWORD]: { open: !isAuth, onClose },
            [MENU_LOGIN]: { open: !isAuth, onClose },
            [MENU_NEW_PASSWORD]: { open: !isAuth && isNewPassAvailable, onClose },
            [MENU_REGISTER]: { open: !isAuth } //Prevent closing registration menu on backdrop click
        };
        const queryMenu = router.query.menu;
        const config = componentsConfigs[queryMenu] || {};
        const isGeneralMenuOpened = queryMenu === MENU_GENERAL;
        const dispatch = useDispatch();

        useEffect(() => {
            router.query.modal && onClose();
        }, [router.query.modal]);

        useEffect(() => {
            return () => {
                if (router.query.menu === MENU_LOGIN) {
                    dispatch(setMFAEnabled(false));
                    dispatch(setMfaToken(''));
                }
            };
        }, [router.query.menu]);

        return (
            <Fragment>
                <PageComponent {...props} />
                {loadingProgress === 100 && (
                    <Fragment>
                        {/*Different styles for general menu*/}
                        <Drawer classes={{ paper: 'small' }} anchor="left" open={isGeneralMenuOpened} onClose={onClose}>
                            {isGeneralMenuOpened && <Menu onClose={onClose}>{menuComponents[queryMenu]}</Menu>}
                        </Drawer>
                        <Drawer {...config} anchor="left">
                            {config.open && <Menu onClose={onClose}>{menuComponents[queryMenu]}</Menu>}
                        </Drawer>
                        <ForgotPasswordResetSuccessModal />
                        <NewPasswordSuccessModal />
                        <NewPasswordExpiredLinkModal />
                        <ActiveSelfExcludedWarningModal />
                    </Fragment>
                )}
            </Fragment>
        );
    };

    return WithSideMenu;
};
