const AlertTriangle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clipPath="url(#clip0_107_15669)">
            <path
                opacity="0.2"
                d="M13.3813 4.14109L0.410442 25.7949C0.143012 26.2581 0.00150943 26.7832 1.20094e-05 27.3179C-0.00148541 27.8527 0.137074 28.3786 0.401906 28.8432C0.666739 29.3078 1.04861 29.695 1.50953 29.9662C1.97045 30.2374 2.49436 30.3832 3.02912 30.3891H28.9709C29.5056 30.3832 30.0295 30.2374 30.4905 29.9662C30.9514 29.695 31.3333 29.3078 31.5981 28.8432C31.8629 28.3786 32.0015 27.8527 32 27.3179C31.9985 26.7832 31.857 26.2581 31.5896 25.7949L18.6187 4.14109C18.3457 3.69102 17.9613 3.31891 17.5026 3.06066C17.0439 2.80242 16.5264 2.66675 16 2.66675C15.4736 2.66675 14.9561 2.80242 14.4974 3.06066C14.0387 3.31891 13.6543 3.69102 13.3813 4.14109Z"
                fill="white"
            />
            <path d="M16 12.0122V18.1378" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 24.2632H16.0153" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_107_15669">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default AlertTriangle;
