import { useDispatch, useSelector } from 'react-redux';
import { MODAL_STATUS_ERROR } from '../../../../variables';
import { handleCloseMenu } from '../../../../utils/locationConfig';
import { imageBaseUrl } from '../../../../services/http';
import { useRouter } from 'next/router';
import routes from '../../../../utils/routes.json';
import { useEffect } from 'react';
import { setGeneralRegistrationError } from '../../../../store/reducers/register';

const RegistrationErrorView = ({ title, details, queries = '' }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const paths = useSelector((state) => state.global.paths);
    const router = useRouter();
    const dispatch = useDispatch();

    const contactSupport = () => {
        if (window.zE && typeof window.zE === 'function') {
            handleCloseMenu();
            window.zE('webWidget', 'open');
        } else {
            router.push({ pathname: routes.contact, query: {} }, paths[routes.contact]);
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setGeneralRegistrationError(false));
        };
    }, []);

    return (
        <div className="registration-error-view" data-testid="registration-error-view">
            <div className="registration-error-view-container">
                <div className="logo-wrapper">
                    <img src={`${imageBaseUrl}/status_${MODAL_STATUS_ERROR}.png`} alt={MODAL_STATUS_ERROR} />
                </div>
                <div className="registration-error-view-body">
                    <div className="registration-error-view-body-title">{title}</div>
                    <div className="registration-error-view-body-content">
                        <div className="content-text">{details}</div>
                        {queries && <div className="content-queries">{queries}</div>}
                    </div>
                </div>
                <div className="form--btns">
                    <button
                        type="button"
                        className="btn btn-cancel btn-modern"
                        data-testid="close_registration_error_view"
                        onClick={handleCloseMenu}
                    >
                        {tr['blocked_registration_later']}
                    </button>
                    <button
                        type="submit"
                        data-testid="contact_support"
                        className="btn btn-submit btn-modern"
                        disabled={false}
                        onClick={contactSupport}
                    >
                        {tr['deposit.flow.result.fail.contact_support']}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegistrationErrorView;
