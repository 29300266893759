import StatusModalContainer from './StatusModalContainer';
import StatusImage from './StatusImage';
import Heading from './Heading';

const StatusModal = ({ isOpen, onClose, children, status, title, content, modalClass = '' }) => {
    const handleClose = (event, reason = undefined) => {
        if (reason === 'backdropClick') {
            event.preventDefault();
        } else onClose();
    };

    return (
        <StatusModalContainer isOpen={isOpen} onClose={handleClose} modalClass={modalClass}>
            <div>
                {status && <StatusImage status={status} />}
                <div>
                    <Heading heading="h2">{title}</Heading>
                    {content}
                </div>
            </div>
            {children}
        </StatusModalContainer>
    );
};

export default StatusModal;
