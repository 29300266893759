import { useDispatch, useSelector } from 'react-redux';
import { countries, isZipCodeValid, setLocationData } from '@tlf-e/brand-utils';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import {
    processEmailVerification,
    processPhoneNumberVerification,
    verifyEmailField,
    verifyPhoneNumberField
} from '../../store/reducers/register';

const GOOGLE_PLACES_API_KEY = 'AIzaSyB9i-8zg__l0EOMuTaXDMz5rlItALlaJK4';

export const withRegisterHelper = (PageComponent) => {
    const WithRegisterHelper = ({ ...props }) => {
        const formData = useSelector((state) => state.register.form);
        const tr = useSelector((state) => state.global.data.translations);
        const locationData = useSelector((state) => state.global.clientIp.loc);
        const currencies = useSelector((state) => state.global.data.currencies);
        const dispatch = useDispatch();
        const userCurrency = countries.find((item) => item.code === formData.country)?.currency;
        const isUserCurrencyEnabled = currencies.find((item) => item === userCurrency?.code);
        const defaultCurrency = isUserCurrencyEnabled ? userCurrency.code : 'EUR';
        const defaultCountry = useMemo(() => countries.find((country) => country.code === locationData), [locationData]);
        const japaneseCharsRegex = /[\u3040-\u30FF\u4E00-\u9FBF\u3400-\u4DBF]/;

        const validateEmail = (value) => {
            let error;
            if (!value) {
                error = tr['register.form.email.error.required'];
            } else if (japaneseCharsRegex.test(value)) {
                error = tr['register.form.email.error.invalid'];
            }

            return error;
        };

        const checkEmail = useCallback(
            debounce((data) => {
                dispatch(verifyEmailField(data));
            }, 500),
            []
        );

        const onChangeEmail = (e, setFieldValue) => {
            const value = e.target.value.trim();
            const err = validateEmail(value);
            const data = {
                email: value
            };
            if (!err) {
                checkEmail(data);
            } else {
                dispatch(
                    processEmailVerification({
                        isLoading: false,
                        isSuccess: false,
                        error: ''
                    })
                );
            }
            setFieldValue('email', data.email);
        };

        const handleCountryOptions = (countryOptions) => {
            return countryOptions.sort((a, b) => (a.name < b.name ? -1 : 1));
        };

        const onChangeAddress = ({ address_components }, setFieldValue) => {
            ['zipCode', 'city', 'country', 'address'].forEach((field) =>
                setLocationData(address_components, setFieldValue, field)
            );
        };

        const validateZipCode = (value, countryCode) => {
            let error;

            if (!value) {
                error = tr['register.form.zip_code.error.required'];
            } else if (!isZipCodeValid(value, countryCode)) {
                error = tr['register.form.zip_code.error.wrong'];
            }

            return error;
        };

        const validatePhoneNumber = (value) => {
            let error;
            if (!value) {
                error = tr['register.form.phone_number.error.required'];
            } else if (value.length < 5 || value.length > 16) {
                error = tr['register.form.phone_number.error.length'];
            }
            return error;
        };

        const checkPhoneNumber = useCallback(
            debounce((value, prefix, error) => {
                dispatch(verifyPhoneNumberField(value, `+${prefix}`, error));
            }, 500),
            []
        );

        const onChangePhoneNumber = (value, prefix, setFieldValue) => {
            const err = validatePhoneNumber(value);
            if (!err) {
                checkPhoneNumber(value, prefix, tr['register.form.phone_number.error.existing']);
            } else {
                dispatch(
                    processPhoneNumberVerification({
                        isLoading: false,
                        isSuccess: false,
                        error: ''
                    })
                );
            }
            setFieldValue();
        };

        const phoneCodeOptions = useMemo(
            () =>
                [...new Map(countries.map((item) => [item['dial_code'], item])).values()].sort(
                    (a, b) => parseInt(a.dial_code) - parseInt(b.dial_code)
                ),
            [countries]
        );

        return (
            <PageComponent
                {...props}
                tr={tr}
                handleCountryOptions={handleCountryOptions}
                onChangeAddress={onChangeAddress}
                googlePlacesApiKey={GOOGLE_PLACES_API_KEY}
                validateZipCode={validateZipCode}
                defaultCountry={defaultCountry}
                defaultCurrency={defaultCurrency}
                onChangePhoneNumber={onChangePhoneNumber}
                phoneCodeOptions={phoneCodeOptions}
                validatePhoneNumber={validatePhoneNumber}
                onChangeEmail={onChangeEmail}
                validateEmail={validateEmail}
            />
        );
    };

    return WithRegisterHelper;
};
