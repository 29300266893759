import { forwardRef } from 'react';
import cx from 'classnames';

const Heading = forwardRef(function Heading({ children, Component = 'p', className, heading, ...props }, ref) {
    return (
        <Component
            {...props}
            ref={ref}
            className={cx(`${heading}`, {
                [`${className}`]: !!className
            })}
        >
            {children}
        </Component>
    );
});

export default Heading;
