import LogoLink from './LogoLink';
import Close from '../svg-icons/Close';

const Menu = ({ onClose, children }) => {
    return (
        <div className="menu" data-testid="menu">
            <div className="menu-content">
                <div className="menu--top">
                    <LogoLink linkProps={{ onClose }} />
                    <div className="menu--close" role="button" data-testid="menu_close" onClick={onClose}>
                        <Close />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Menu;
