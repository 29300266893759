import { useMemo } from 'react';
import * as yup from 'yup';
import { allowSpecialRegex, getIsJapanese } from '../utils/commonConfig';

const useRegisterValidationSchema = (tr) => {
    return useMemo(
        () =>
            yup.object({
                password: yup
                    .string()
                    .required(tr['register.form.password.error.required'])
                    .min(8, tr['register.form.password.error.hint'])
                    .max(255, tr['register.form.password.error.hint'])
                    .matches(
                        /[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]/g,
                        tr['register.form.password.error.hint']
                    )
                    .matches(/\d/g, tr['register.form.password.error.hint'])
                    .matches(
                        /[!"#$%&()*+,-./:;<=>?@[\]^_`{|}~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿]/g,
                        tr['register.form.password.error.hint']
                    )
                    .matches(/^((?!\s).)*$/, tr['register.form.password.error.hint'])
                    .notOneOf([yup.ref('email')], tr['register.password.sameemail']),
                termsConditions: yup.boolean().oneOf([true], tr['register.terms_accept.error.required']),
                firstname: yup
                    .string()
                    .required(tr['register.form.firstname.error.required'])
                    .test('is-valid-firstname-kanji', tr['register.form.firstname.error.wrong'], (value) =>
                        getIsJapanese(value)
                    ),
                lastname: yup
                    .string()
                    .required(tr['register.form.lastname.error.required'])
                    .test('is-valid-lastname-kanji', tr['register.form.lastname.error.wrong'], (value) =>
                        getIsJapanese(value)
                    ),
                birthDay: yup
                    .number()
                    .moreThan(0, tr['register.form.birth_day.error.wrong'])
                    .lessThan(32, tr['register.form.birth_day.error.wrong'])
                    .required(tr['register.form.birth_day.error.required']),
                birthMonth: yup
                    .number()
                    .moreThan(0, tr['register.form.birth_month.error.wrong'])
                    .lessThan(13, tr['register.form.birth_month.error.wrong'])
                    .required(tr['register.form.birth_month.error.required']),
                birthYear: yup
                    .number()
                    .moreThan(new Date().getFullYear() - 121, tr['register.form.birth_year.error.wrong'])
                    .lessThan(new Date().getFullYear() - 17, tr['register.form.birth_year.error.wrong'])
                    .required(tr['register.form.birth_year.error.required']),
                gender: yup.string().required(tr['register.form.gender.error.required']),
                address: yup
                    .string()
                    .required(tr['register.form.address.error.required'])
                    .min(2, tr['register.form.address.error.wrong']),
                city: yup
                    .string()
                    .matches(allowSpecialRegex, tr['register.form.city.error.wrong'])
                    .required(tr['register.form.city.error.required'])
                    .min(2, tr['register.form.city.error.wrong']),
                first_name_romaji: yup
                    .string()
                    .required(tr['register.form.firstname.error.required'])
                    .test(
                        'is-valid-firstname-romaji',
                        tr['register.form.firstname.error.wrong'],
                        (value) => allowSpecialRegex.test(value) && !getIsJapanese(value)
                    )
                    .min(2, tr['register.form.firstname.error.wrong'])
                    .max(255, tr['register.form.firstname.error.wrong']),
                last_name_romaji: yup
                    .string()
                    .required(tr['register.form.lastname.error.required'])
                    .test(
                        'is-valid-lastname-romaji',
                        tr['register.form.lastname.error.wrong'],
                        (value) => allowSpecialRegex.test(value) && !getIsJapanese(value)
                    )
                    .min(2, tr['register.form.lastname.error.wrong'])
                    .max(255, tr['register.form.lastname.error.wrong'])
            }),
        [tr]
    );
};

export default useRegisterValidationSchema;
