import { sliderWorkers } from '../../services/http';
import { dataLayerPush, handleValidJson, getAffiliateDataValue, handleRequestError } from '@tlf-e/brand-utils';
import { includeRouterContext } from '../../utils/locationConfig';
import defaultBanner from '../../utils/banner.json';

const { sliderRequest } = sliderWorkers;

const GET_SLIDER_CONTENT = 'GET_SLIDER_CONTENT';
const GET_SLIDER_CONTENT_SUCCESS = 'GET_SLIDER_CONTENT_SUCCESS';
const GET_SLIDER_CONTENT_FAILED = 'GET_SLIDER_CONTENT_FAILED';

export const initialState = {
    data: {
        slider: []
    },
    isLoading: false,
    sliderIsLoaded: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_SLIDER_CONTENT:
        return {
            ...state,
            isLoading: true
        };
    case GET_SLIDER_CONTENT_SUCCESS:
        return {
            ...state,
            data: {
                slider: action.payload
            },
            isLoading: false,
            sliderIsLoaded: true
        };
    case GET_SLIDER_CONTENT_FAILED:
        return {
            ...state,
            data: {
                slider: []
            },
            isLoading: false,
            sliderIsLoaded: false
        };
    default:
        return state;
    }
};

export const submittingSlider = () => ({
    type: GET_SLIDER_CONTENT
});

export const getContentForSlider = (lang) => {
    const contentForSlider = (payload) => ({
        type: GET_SLIDER_CONTENT_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_SLIDER_CONTENT_FAILED
    });
    return (dispatch, getState) => {
        const aid = includeRouterContext(getAffiliateDataValue, 'aid');
        const isAuth = getState().user.isAuth;
        const hasAid = !isAuth && aid ? aid : undefined;
        dispatch(submittingSlider());
        sliderRequest(lang, hasAid)
            .then((res) => {
                const sliderContent = res.data.data.sliders
                    .filter((slide) => handleValidJson(slide))
                    .map((slide) => JSON.parse(slide));

                if (sliderContent.length > 0) {
                    dispatch(contentForSlider(sliderContent));
                } else {
                    const country = getState().global.clientIp.loc;
                    dispatch(contentForSlider([defaultBanner[lang]]));
                    dataLayerPush({
                        event: 'Banner_not_assigned',
                        affiliate_id: aid,
                        logged_in: isAuth,
                        country: country
                    });
                }
            })
            .catch((err) => {
                fail();
                includeRouterContext(handleRequestError, err);
            });
    };
};
