import { createContext, useContext } from 'react';

export const ssrContextDefault = {
    device: {},
    hostName: ''
};

export const SSRContext = createContext(ssrContextDefault);

export const useSSRContext = () => useContext(SSRContext);
