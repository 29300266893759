import { captainUpWorkers } from '../../services/http';

const { getCaptainUpToken } = captainUpWorkers;

const INIT_CAPTAIN_UP = 'INIT_CAPTAIN_UP';
const GET_CAPTAIN_UP_LOADING = 'GET_CAPTAIN_UP_LOADING';
const GET_CAPTAIN_UP_SUCCESS = 'GET_CAPTAIN_UP_SUCCESS';
const GET_CAPTAIN_UP_ERROR = 'GET_CAPTAIN_UP_ERROR';
const SETUP_CAPTAIN_UP = 'SET_CAPTAIN_UP';

const initialState = {
    signed_user: '',
    captainUpKey: '',
    captainUpToken: '',
    captainUpTokenLoading: false,
    error: null,
    setupCaptainUp: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case INIT_CAPTAIN_UP:
        return {
            ...state,
            signed_user: '',
            captainUpKey: '',
            captainUpToken: '',
            captainUpTokenLoading: false,
            error: null,
            setupCaptainUp: false
        };
    case GET_CAPTAIN_UP_LOADING:
        return {
            ...state,
            captainUpTokenLoading: true,
            signed_user: ''
        };
    case GET_CAPTAIN_UP_SUCCESS:
        return {
            ...state,
            captainUpTokenLoading: false,
            captainUpKey: action.payload.api_key,
            captainUpToken: action.payload.client_token,
            signed_user: action.payload.signed_user
        };
    case GET_CAPTAIN_UP_ERROR:
        return {
            ...state,
            captainUpTokenLoading: false,
            error: action.payload,
            signed_user: ''
        };
    case SETUP_CAPTAIN_UP:
        return {
            ...state,
            setupCaptainUp: action.payload
        };
    default:
        return state;
    }
};

export const initCaptainUp = () => ({
    type: INIT_CAPTAIN_UP
});

export const getCaptainUpUser = () => {
    const loading = () => ({
        type: GET_CAPTAIN_UP_LOADING
    });
    const success = (payload) => ({
        type: GET_CAPTAIN_UP_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: GET_CAPTAIN_UP_ERROR,
        payload
    });

    return (dispatch) => {
        dispatch(loading());
        getCaptainUpToken()
            .then((res) => {
                dispatch(success(res.data.data));
            })
            .catch((error) => {
                dispatch(fail(error));
            });
    };
};

export const setCaptainUpSetup = (payload) => ({
    type: SETUP_CAPTAIN_UP,
    payload
});
