import { useFormikContext, Field } from 'formik';
import cx from 'classnames';
import FormErrorMessage from '../../FormErrorMessage';
import { dataLayerPush } from '@tlf-e/brand-utils';

const NameSurnameField = ({ title, fieldName, fieldId }) => {
    const { touched, errors } = useFormikContext();

    return (
        <div className="form-group--cell">
            <label className="form-group--title form-group-title">{title}</label>
            <div
                className={cx({
                    'form-group--cell-error-marked': touched[fieldName] && errors[fieldName]
                })}
            >
                <Field name={fieldName}>
                    {({ field }) => (
                        <input
                            {...field}
                            type="text"
                            id={fieldId}
                            data-testid={fieldId}
                            autoComplete="off"
                            autoCapitalize="off"
                            className="text-field"
                            value={field.value || ''}
                            onBlur={(e) => {
                                e.target.value &&
                                    dataLayerPush({
                                        event: 'signup_filled_field',
                                        field: { fieldName }
                                    });
                            }}
                        />
                    )}
                </Field>
                <FormErrorMessage name={fieldName} />
            </div>
        </div>
    );
};

export default NameSurnameField;
