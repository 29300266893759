import { useDispatch, useSelector } from 'react-redux';
import { setShowActiveSelfExcludedModal } from '../../store/reducers/user';
import ContactSupportModal from './ContactSupportModal';

const ActiveSelfExcludedWarningModal = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { showActiveSelfExcludedModal } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setShowActiveSelfExcludedModal(false));
    };

    return (
        <ContactSupportModal
            isOpen={showActiveSelfExcludedModal}
            onClose={onClose}
            content={
                <div className="wdr-success-content">
                    <div>{tr['account_excluded.description']}</div>
                </div>
            }
            tr={tr}
            title={tr['account_excluded.title']}
        />
    );
};

export default ActiveSelfExcludedWarningModal;
