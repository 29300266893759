import { useDispatch, useSelector } from 'react-redux';
import { withRegisterHelper } from '../../../hoc/withRegisterHelper';
import { cleanupSubmitRegistration, registerUser, setActiveStep } from '../../../../store/reducers/register';
import dayjs from 'dayjs';
import getRouterLocale from '../../../../utils/getRouterLocale';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { countries, dataLayerPush, getAffiliateDataValue } from '@tlf-e/brand-utils';
import { includeRouterContext } from '../../../../utils/locationConfig';

const RegisterStep2New = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.global.data.translations);
    const { form, submitRegistration } = useSelector((state) => state.register);
    const predefinedCommunicationChannels = useSelector((state) => state.user.predefinedCommunicationChannels);
    const currencies = useSelector((state) => state.global.data.currencies);
    const ipCountry = useSelector((state) => state.global.clientIp.loc);
    const userCurrencyByIp = countries.find((item) => item.code === ipCountry)?.currency?.code;
    const defaultCurrency = currencies.includes(userCurrencyByIp) ? userCurrencyByIp : 'JPY';
    const { isError, error, isLoading, isSuccess } = submitRegistration;
    const disabledRegistration = isLoading || isSuccess;

    const setCommunicationChannels = (value) => {
        return predefinedCommunicationChannels.reduce((acc, channel) => {
            acc[channel] = value;
            return acc;
        }, {});
    };

    const {
        birthDay,
        birthMonth,
        birthYear,
        marketingAllowed,
        first_name_romaji,
        last_name_romaji,
        city_romaji,
        address_romaji,
        ...formData
    } = form;
    const birthDate = useMemo(() => {
        const month = String(birthMonth).padStart(2, '0');
        return dayjs(`${birthYear}-${month}-${birthDay}`).format('YYYY-MM-DD');
    }, [birthDay, birthMonth, birthYear]);
    const extraFields = useMemo(() => {
        const communicationChannels = setCommunicationChannels(marketingAllowed);

        return {
            birthDate,
            communicationChannels: communicationChannels,
            currency: defaultCurrency,
            language: getRouterLocale(router.locale).capitalizedLocale,
            affiliateId: includeRouterContext(getAffiliateDataValue, 'aid'),
            tracker: includeRouterContext(getAffiliateDataValue, 'at'),
            customAttributes: {
                first_name_romaji,
                last_name_romaji,
                city_romaji,
                address_romaji
            }
        };
    }, [birthDate, marketingAllowed, defaultCurrency]);
    const displayFields = useMemo(() => {
        return {
            'register.form.email': formData.email,
            'register.form.lastname.romanji': last_name_romaji || '-',
            'register.form.firstname.romanji': first_name_romaji || '-',
            'register.form.lastname.kanji': formData.lastname,
            'register.form.firstname.kanji': formData.firstname,
            'register.birthdate': birthDate,
            'register.gender': formData.gender,
            'register.phone_number': `+${formData.countryPhoneCodePrefix} ${formData.phone}`,
            'register.address': `${formData.zipCode} ${formData.city}, ${formData.address}`
        };
    }, [formData, birthDate]);

    useEffect(() => {
        dataLayerPush({ event: 'signup_step2' });
        return () => {
            dispatch(cleanupSubmitRegistration());
        };
    }, []);

    const onSubmit = () => {
        dispatch(registerUser({ ...formData, ...extraFields }, tr));
    };

    return (
        <div id="register_form_new">
            <div className="form--title">
                <span>{tr['register.form.confirm_title']}</span>
            </div>
            {isError && <div className="page-message page-message--error">{error}</div>}
            <div className="register--form--confirmation">
                <div className="confirm-registration">
                    {Object.entries(displayFields).map(([key, value]) => {
                        return (
                            <div key={key} className="information-wrapper">
                                <span className="title">{tr[key]}</span>
                                <span className="value">{value}</span>
                            </div>
                        );
                    })}
                </div>
                <div className="btns-container">
                    <button
                        type="button"
                        className="btn btn-tertiary btn-modern"
                        data-testid="register_step_back"
                        onClick={() => {
                            dispatch(setActiveStep(1));
                        }}
                    >
                        {tr['go.back']}
                    </button>
                    <button
                        type="submit"
                        onClick={onSubmit}
                        className="btn btn-submit btn-modern"
                        disabled={disabledRegistration}
                    >
                        {disabledRegistration ? tr['global.loading'] : tr['register.create_account']}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withRegisterHelper(RegisterStep2New);
