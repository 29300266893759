import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLocalStorageAvailable } from '../../../utils/localStorageHelper';
import { dataLayerPush, getAffiliateDataValue } from '@tlf-e/brand-utils';
import {
    checkRegistrationIsAllowed,
    initRegisterUser,
    removeRegistrationAllowedLoaded,
    setIsInteracted
} from '../../../store/reducers/register';
import RegisterStep1New from './RegisterStep1New';
import RegisterStep2New from './steps/RegisterStep2New';
import PageLoader from '../PageLoader';
import RegistrationErrorView from './fragments/RegistrationErrorView';
import { includeRouterContext } from '../../../utils/locationConfig';

const forms = {
    1: <RegisterStep1New />,
    2: <RegisterStep2New />
};

const RegisterNew = () => {
    const loc = useSelector((state) => state.global.clientIp.loc);
    const { isInteracted } = useSelector((state) => state.register);
    const { activeStep } = useSelector((state) => state.register);
    const { generalRegistrationError } = useSelector((state) => state.register);
    const tr = useSelector((state) => state.global.data.translations);
    const { isRegistrationAllowedLoaded, isRegistrationAllowed } = useSelector((state) => state.register);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(initRegisterUser());
            dispatch(setIsInteracted(false));
        };
    }, []);

    useEffect(() => {
        isInteracted && dataLayerPush({ event: 'signup_start' });
    }, [isInteracted]);

    useEffect(() => {
        if (isLocalStorageAvailable()) {
            const affiliateId = includeRouterContext(getAffiliateDataValue, 'aid');
            dispatch(checkRegistrationIsAllowed(affiliateId, loc));
            return () => dispatch(removeRegistrationAllowedLoaded());
        }
    }, []);

    return (
        <div className="form-container">
            {isRegistrationAllowedLoaded ? (
                generalRegistrationError ? (
                    <RegistrationErrorView
                        title={tr['registration.general.error.title']}
                        details={tr['registration.general.error.details']}
                    />
                ) : isRegistrationAllowed ? (
                    forms[activeStep]
                ) : (
                    <RegistrationErrorView
                        title={tr['not_logged.title']}
                        details={tr['blocked_registration_attempted_wrong_geo_loc']}
                        queries={tr['blocked_registration_queries']}
                    />
                )
            ) : (
                <div className="page-loader-wrapper">
                    <PageLoader />
                </div>
            )}
        </div>
    );
};

export default RegisterNew;
