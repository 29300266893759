import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import cx from 'classnames';
import FormErrorMessage from '../../FormErrorMessage';
import { dataLayerPushField } from '@tlf-e/brand-utils';

const EmailField = ({ onChangeEmail, validateEmail }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { touched, errors, setFieldValue } = useFormikContext();
    const [blurredValue, setBlurredValue] = useState('');
    const { emailFieldVerification } = useSelector((state) => state.register);

    useEffect(() => {
        if (blurredValue && blurredValue === emailFieldVerification.value) {
            dataLayerPushField({
                event: 'signup_filled_field',
                field: 'email',
                valid: !emailFieldVerification.error,
                error: emailFieldVerification.error
            });
        }
    }, [blurredValue, emailFieldVerification.value]);

    return (
        <div
            className={cx('form-group', {
                'form-group--error-marked': touched.email && (errors.email || emailFieldVerification.error),
                'form-group--loading': emailFieldVerification.isLoading
            })}
        >
            <label htmlFor="register_email">{tr['register.form.email']}</label>
            {emailFieldVerification.isLoading && <div className="loader" />}
            <Field name="email" validate={validateEmail}>
                {({ field }) => (
                    <input
                        {...field}
                        type="text"
                        id="register_email"
                        data-testid="register_email"
                        autoComplete="off"
                        autoCapitalize="off"
                        className="text-field"
                        data-hj-allow
                        onChange={(e) => onChangeEmail(e, setFieldValue)}
                        onBlur={(e) => setBlurredValue(e.target.value)}
                    />
                )}
            </Field>
            <FormErrorMessage name="email" />
            {emailFieldVerification.error && touched.email && !errors.email && (
                <span className="form-message form-message--error">{emailFieldVerification.error}</span>
            )}
            <span className="form-field-tip">{tr['register.form.email.tip']}</span>
        </div>
    );
};

export default EmailField;
