import { default as MuiModal } from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { Fragment } from 'react';

const Modal = ({ children, className = '', transitionEffect = 'fade', ...props }) => {
    const timeout = 300;

    return (
        <MuiModal
            open={props.open}
            className={`modal modal_${transitionEffect} ${className}`}
            disableScrollLock
            slotProps={{
                backdrop: { className: 'modal-backdrop' }
            }}
            {...props}
        >
            <Fragment>
                {/* This code has been added to hide captainUp on when modal is open */}
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                    #_captain_up { display: none; }
                `
                    }}
                />
                {transitionEffect === 'slide' ? (
                    <Slide in={props.open} direction="up" timeout={{ enter: timeout, exit: timeout }}>
                        {children}
                    </Slide>
                ) : (
                    <Fade in={props.open} timeout={{ enter: timeout, exit: timeout }}>
                        {children}
                    </Fade>
                )}
            </Fragment>
        </MuiModal>
    );
};

export default Modal;
