const Close = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.348992 0.348761C0.77195 -0.0740416 1.43374 -0.112414 1.90005 0.2336L2.03365 0.348936L5.00017 3.3156L7.96406 0.352184C8.42926 -0.112947 9.18351 -0.112947 9.64871 0.352184C10.0716 0.775031 10.1101 1.43672 9.76405 1.90299L9.64871 2.03657L6.68465 5.00017L9.65118 7.96685C10.1163 8.43203 10.1163 9.18616 9.65101 9.65124C9.22805 10.074 8.56626 10.1124 8.09995 9.7664L7.96635 9.65106L4.99999 6.68456L2.03753 9.64655C1.57233 10.1117 0.81808 10.1117 0.352875 9.64655C-0.0700388 9.2237 -0.108486 8.56201 0.237534 8.09574L0.352875 7.96216L3.31551 4.99999L0.348815 2.03315C-0.116341 1.56797 -0.116261 0.813844 0.348992 0.348761Z"
            fill="#916D46"
        />
    </svg>
);

export default Close;
