import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { getMenuUrl } from '../../../utils/locationConfig';
import { MENU_LOGIN } from '../../../variables';
import Link from 'next/link';
import {
    cleanupSubmitRegistration,
    combineRegistrationForm,
    setActiveStep,
    setIsInteracted
} from '../../../store/reducers/register';
import { withRegisterHelper } from '../../hoc/withRegisterHelper';
import cx from 'classnames';
import FormErrorMessage from '../FormErrorMessage';
import { useSSRContext } from '../../../hooks/useSSRContext';
import {
    dataLayerPush,
    dataLayerPushField,
    dayMaskBuilder,
    monthMaskBuilder,
    yearMaskBuilder,
    onInputNumber
} from '@tlf-e/brand-utils';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import MaskedInput from 'react-text-mask/dist/reactTextMask';
import Autocomplete from 'react-google-autocomplete';
import PhoneNumber from './fragments/PhoneNumber';
import EmailField from './fragments/EmailField';
import useRegisterValidationSchema from '../../../hooks/useRegisterValidationSchema';
import PasswordField from '../password';
import NameSurnameField from './fragments/NameSurnameField';
import { getPlaceIdInfo } from '../../../utils/formUtils';

const RegisterStep1New = ({
    tr,
    onChangeEmail,
    validateEmail,
    defaultCountry,
    googlePlacesApiKey,
    validateZipCode,
    onChangeAddress
}) => {
    const { emailFieldVerification, isInteracted, form } = useSelector((state) => state.register);
    const { hostName } = useSSRContext();
    const router = useRouter();
    const dispatch = useDispatch();
    const birthdateRef = useRef();
    const validationSchema = useRegisterValidationSchema(tr);
    const romanjiFields = {
        city: 'city_romaji',
        address: 'address_romaji'
    };

    useEffect(() => {
        dataLayerPush({ event: 'signup_step1' });
        return () => {
            dispatch(cleanupSubmitRegistration());
        };
    }, []);

    const handleOnChangeAddress = async (placeId, setFieldValueForm) => {
        const addressComponents = await getPlaceIdInfo(placeId);
        const { addressComponentsJA, addressComponentsEN } = addressComponents;
        //apply kanji address to the form data and display form
        onChangeAddress({ address_components: addressComponentsJA }, setFieldValueForm);

        //apply the romanji address to the form data only
        onChangeAddress({ address_components: addressComponentsEN }, (field, value) => {
            romanjiFields[field] && setFieldValueForm(romanjiFields[field], value);
        });
    };

    const initialValues = {
        email: form.email,
        password: form.password,
        termsConditions: form.termsConditions,
        firstname: form.firstname,
        lastname: form.lastname,
        birthDay: form.birthDay,
        birthMonth: form.birthMonth,
        birthYear: form.birthYear,
        gender: form.gender,
        address: form.address,
        zipCode: form.zipCode,
        city: form.city,
        country: form.country || defaultCountry?.code,
        countryPhoneCodePrefix: form.countryPhoneCodePrefix || defaultCountry?.dial_code,
        phone: form.phone,
        marketingAllowed: form.marketingAllowed || false,
        first_name_romaji: form.first_name_romaji,
        last_name_romaji: form.last_name_romaji,
        city_romaji: form.city_romaji,
        address_romaji: form.address_romaji
    };

    const handleSuccess = (values) => {
        dispatch(combineRegistrationForm({ ...values }));
        dispatch(setActiveStep(2));
    };

    const onSubmit = (values) => {
        !emailFieldVerification.isSuccess && validateEmail(values.email);
        emailFieldVerification.isSuccess && handleSuccess(values);
    };

    const focusChange = (item) => {
        if (birthdateRef.current.childNodes[item].querySelector('input').value.length >= 2) {
            birthdateRef.current.childNodes[item + 1].querySelector('input').focus();
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ values, touched, errors, setFieldValue }) => (
                <Fragment>
                    <div className="form--title">
                        <span>{tr['register.form.title']}</span>
                    </div>
                    <Form
                        id="register_form_new"
                        onChange={() => {
                            !isInteracted && dispatch(setIsInteracted(true));
                        }}
                    >
                        <EmailField onChangeEmail={onChangeEmail} validateEmail={validateEmail} />
                        <PasswordField
                            fieldName="password"
                            title={tr['register.form.password']}
                            testId="register_password"
                            dataLayerEvent="signup_filled_field"
                        />

                        <div className="form-group">
                            <div className="form-group--row">
                                <NameSurnameField
                                    title={tr['register.form.lastname.romanji']}
                                    fieldName="last_name_romaji"
                                    fieldId="register_last_name_romaji"
                                />
                                <NameSurnameField
                                    title={tr['register.form.firstname.romanji']}
                                    fieldName="first_name_romaji"
                                    fieldId="register_first_name_romaji"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-group--row">
                                <NameSurnameField
                                    title={tr['register.form.lastname.kanji']}
                                    fieldName="lastname"
                                    fieldId="register_player_last_name_kanji"
                                />
                                <NameSurnameField
                                    title={tr['register.form.firstname.kanji']}
                                    fieldName="firstname"
                                    fieldId="register_player_first_name_kanji"
                                />
                            </div>
                            <span className="form-field-tip">{tr['register.form.first_last_name.tip']}</span>
                        </div>
                        <div className="form-group">
                            <label className="form-group--title form-group-title">{tr['register.birthdate']}</label>
                            <div className="form-group--row" ref={birthdateRef}>
                                <div
                                    className={cx('form-group--cell', {
                                        'form-group--cell-error-marked': touched.birthDay && errors.birthDay
                                    })}
                                >
                                    <Field name="birthDay">
                                        {({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                mask={dayMaskBuilder(field.value)}
                                                guide={false}
                                                type="text"
                                                placeholder="DD"
                                                maxLength="2"
                                                inputMode="numeric"
                                                id="register_birthDay"
                                                data-testid="register_birthDay"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onKeyUp={() => focusChange(0)}
                                                onInput={onInputNumber}
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'birthdate_day'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="birthDay" />
                                </div>
                                <div
                                    className={cx('form-group--cell', {
                                        'form-group--cell-error-marked': touched.birthMonth && errors.birthMonth
                                    })}
                                >
                                    <Field name="birthMonth">
                                        {({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                mask={monthMaskBuilder(field.value)}
                                                guide={false}
                                                maxLength="2"
                                                type="text"
                                                placeholder="MM"
                                                inputMode="numeric"
                                                id="register_birthMonth"
                                                data-testid="register_birthMonth"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onKeyUp={() => focusChange(1)}
                                                onInput={onInputNumber}
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'birthdate_month'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="birthMonth" />
                                </div>
                                <div
                                    className={cx('form-group--cell', {
                                        'form-group--cell-error-marked': touched.birthYear && errors.birthYear
                                    })}
                                >
                                    <Field name="birthYear">
                                        {({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                mask={yearMaskBuilder(field.value)}
                                                guide={false}
                                                type="text"
                                                placeholder="YYYY"
                                                inputMode="numeric"
                                                maxLength="4"
                                                id="register_birthYear"
                                                data-testid="register_birthYear"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onInput={onInputNumber}
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'birthdate_year'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="birthYear" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-group--title form-group-title">{tr['register.gender']}</label>
                            <Field name="gender">
                                {({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        className="radio-group"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            dataLayerPush({
                                                event: 'signup_filled_field',
                                                field: 'gender'
                                            });
                                        }}
                                    >
                                        {['male', 'female'].map((value) => {
                                            const label = tr[`register.gender.${value}`];
                                            return (
                                                <FormControlLabel
                                                    value={value}
                                                    key={value}
                                                    control={<Radio data-testid={`register_gender_${value}`} />}
                                                    label={label}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                )}
                            </Field>
                            <FormErrorMessage name="gender" />
                        </div>
                        <PhoneNumber />
                        <div className="form-group">
                            <div className="form-group--row">
                                <div className="form-group--cell">
                                    <label className="form-group--title form-group-title">
                                        {tr['register.form.zip_code']}
                                    </label>
                                    <div
                                        className={cx({
                                            'form-group--cell-error-marked': touched.zipCode && errors.zipCode
                                        })}
                                    >
                                        <Field name="zipCode" validate={(value) => validateZipCode(value, values.country)}>
                                            {({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    id="register_zip_code"
                                                    data-testid="register_zipCode"
                                                    autoComplete="off"
                                                    autoCapitalize="off"
                                                    className="text-field"
                                                    onBlur={(e) => {
                                                        const input = e.target.value;
                                                        input &&
                                                            dataLayerPushField({
                                                                event: 'signup_filled_field',
                                                                field: 'postcode',
                                                                input: input,
                                                                valid: !errors.zipCode,
                                                                error: errors.zipCode
                                                            });
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <FormErrorMessage name="zipCode" />
                                    </div>
                                </div>
                                <div className="form-group--cell">
                                    <label className="form-group--title form-group-title" htmlFor="register_city">
                                        {tr['register.form.city']}
                                    </label>
                                    <div
                                        className={cx({
                                            'form-group--cell-error-marked': touched.city && errors.city
                                        })}
                                    >
                                        <Field name="city">
                                            {({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    id="register_city"
                                                    data-testid="register_city"
                                                    autoComplete="off"
                                                    autoCapitalize="off"
                                                    className="text-field"
                                                    onBlur={(e) => {
                                                        e.target.value &&
                                                            dataLayerPush({
                                                                event: 'signup_filled_field',
                                                                field: 'city'
                                                            });
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <FormErrorMessage name="city" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-group--title form-group-title">{tr['register.address']}</label>
                            <div
                                className={cx({
                                    'form-group--error-marked': touched.address && errors.address
                                })}
                            >
                                <Field name="address">
                                    {({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            apiKey={googlePlacesApiKey}
                                            className="text-field"
                                            type="text"
                                            name="address"
                                            data-testid="register_address"
                                            placeholder={tr['register.form.address.placeholder']}
                                            options={{
                                                types: ['address'],
                                                componentRestrictions: {
                                                    country: values.country
                                                }
                                            }}
                                            id="register_address"
                                            onPlaceSelected={(place) => handleOnChangeAddress(place.place_id, setFieldValue)}
                                            onBlur={(e) => {
                                                e.target.value &&
                                                    dataLayerPush({
                                                        event: 'signup_filled_field',
                                                        field: 'address'
                                                    });
                                            }}
                                        />
                                    )}
                                </Field>
                                <FormErrorMessage name="address" />
                            </div>
                        </div>
                        <div className="form-group">
                            <Field
                                id="register_terms"
                                name="termsConditions"
                                type="checkbox"
                                onClick={(e) => {
                                    if (e.target.checked) {
                                        dataLayerPush({ event: 'signup_terms_age' });
                                        dataLayerPush({ event: 'signup_terms_privacy' });
                                    }
                                }}
                                data-testid="register_terms"
                            />
                            <label
                                id="register_terms"
                                htmlFor="register_terms"
                                dangerouslySetInnerHTML={{
                                    __html: tr['register.terms']
                                        .replace(
                                            '##TERMS_LINK##',
                                            // eslint-disable-next-line max-len
                                            `<a href='${hostName}/${router.locale}/terms' target='_blank'>${tr['footer.nav.terms']}</a>`
                                        )
                                        // eslint-disable-next-line max-len
                                        .replace(
                                            '##PRIVACY_LINK##',
                                            // eslint-disable-next-line max-len
                                            `<a href='${hostName}/${router.locale}/privacy-policy' target='_blank'>${tr['footer.nav.privacy_policy']}</a>`
                                        )
                                }}
                            />
                            <FormErrorMessage name="termsConditions" />
                        </div>
                        <div className="form-group">
                            <Field
                                id="marketing_allowed"
                                name="marketingAllowed"
                                type="checkbox"
                                data-testid="marketing_allowed"
                            />
                            <label
                                htmlFor="marketing_allowed"
                                dangerouslySetInnerHTML={{ __html: tr['register.commercial'] }}
                            />
                        </div>
                        <div className="form--btns">
                            <Link {...getMenuUrl(MENU_LOGIN, router)}>
                                <button type="button" className="btn btn-tertiary btn-modern">
                                    {tr['header.login']}
                                </button>
                            </Link>
                            <button type="submit" className="btn btn-submit btn-modern" data-testid="register_step_1">
                                {tr['register.continue']}
                            </button>
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
};

export default withRegisterHelper(RegisterStep1New);

//TODO: Delete translation keys:
//code_send_btn
//register_send_code.btn
//register.form.field.verification_code.info
//register.form.field.phone_number.info
//register.form.field.verification_code.invalid
//register.form.field.verification_code.valid
//register.form.field.verification_code
//register.error.verification_code_invalid
//register.form.field.verification_code.resend
