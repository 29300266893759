import {
    PAYMENT_METHOD_AMERICAN_EXPRESS_LIBRARY,
    PAYMENT_METHOD_AMEX,
    PAYMENT_METHOD_DINERS,
    PAYMENT_METHOD_DINERS_LIBRARY,
    PAYMENT_METHOD_JCB
} from '../variables';

export const inputMasks = {
    voucherNumber: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ]
};

const generateMaskInputFromFormat = (format) => {
    return format.split('').map((curr) => (curr === 'x' ? /\d/ : ' '));
};

export const cardsConfig = {
    [PAYMENT_METHOD_AMEX]: {
        type: PAYMENT_METHOD_AMERICAN_EXPRESS_LIBRARY,
        length: [15],
        cvv: 4,
        maskedCvv: generateMaskInputFromFormat('xxxx'),
        formatRegex: /(.{4})(.{6})(.{5})/g,
        maskedCardNumber: generateMaskInputFromFormat('xxxx xxxxxx xxxxx')
    },
    [PAYMENT_METHOD_JCB]: {
        type: PAYMENT_METHOD_JCB,
        length: [15, 16],
        cvv: 3,
        maskedCvv: generateMaskInputFromFormat('xxx'),
        formatRegex: /(.{4})/g,
        maskedCardNumber: generateMaskInputFromFormat('xxxx xxxx xxxx xxxx')
    },
    [PAYMENT_METHOD_DINERS]: {
        type: PAYMENT_METHOD_DINERS_LIBRARY,
        length: [14],
        cvv: 3,
        maskedCvv: generateMaskInputFromFormat('xxx'),
        formatRegex: /(.{4})(.{6})(.{4})/g,
        maskedCardNumber: generateMaskInputFromFormat('xxxx xxxxxx xxxxx')
    },
    default: {
        type: '',
        length: [14, 15, 16, 17, 18, 19],
        cvv: 3,
        maskedCvv: generateMaskInputFromFormat('xxx'),
        formatRegex: /(.{4})/g,
        maskedCardNumber: generateMaskInputFromFormat('xxxx xxxx xxxx xxxx')
    }
};

export const getPlaceIdInfo = async (placeId) => {
    // eslint-disable-next-line no-undef
    const { Place } = google && (await google.maps.importLibrary('places'));

    const createPlaceRequest = async (language) => {
        const place = new Place({
            id: placeId,
            requestedLanguage: language
        });

        await place.fetchFields({
            fields: ['addressComponents']
        });

        return {
            language,
            displayName: place.displayName,
            formattedAddress: place.formattedAddress,
            address_components: place.addressComponents
        };
    };

    const [enResult, jaResult] = await Promise.all([createPlaceRequest('en'), createPlaceRequest('ja')]);

    const convertAddressComponents = ({ address_components }) => {
        return address_components.map((item) => {
            return {
                long_name: item.longText,
                short_name: item.shortText,
                types: item.types
            };
        });
    };

    return {
        addressComponentsJA: convertAddressComponents(jaResult),
        addressComponentsEN: convertAddressComponents(enResult)
    };
};
