export const HTML_DOC_ID_DESKTOP = 'yk-page';
export const HTML_DOC_ID_MOBILE = 'm-yk-page';
export const CURRENT_DEVICE_MOBILE = 'mobile';
export const CURRENT_DEVICE_DESKTOP = 'desktop';
export const LANGUAGE_EN = 'en';
export const LANGUAGE_JA_JP = 'ja-jp';
export const COUNTRY_CODE_CA = 'CA';
export const MAINTENANCE_STATUS = 'maintenance';
export const MAINTENANCE_CODE = 503;
export const COOKIE_NAME_SESSION = 'session';
export const COOKIE_NAME_TOKEN = 'token';
export const COOKIE_NAME_PRIVACY_POLICY = 'acceptedPrivacyPolicy';
export const COOKIE_NAME_AUTH_TYPE = 'authType';
export const COOKIE_NAME_SUSPENDED_CHECKING = 'suspendedChecking';
export const COOKIE_DOMAIN_LOCAL = '';
export const COOKIE_DOMAIN_STAGING = 'tucuxi.io';
export const COOKIE_DOMAIN_PRODUCTION = 'yokikana.com';
export const COOKIE_SHOW_PROMOTION_LOGIN_MODAL = 'showPromotionLoginModal';
export const YOKIKANA = 'Yokikana';
export const CMS_SLUG_CONTACT = 'contact';
export const CMS_SLUG_RESPONSIBLE_GAMING = 'responsible_gaming';
export const CMS_SLUG_TERMS = 'terms';
export const CMS_PAGE_SLUG_TERMS = 'terms_and_conditions';
export const CMS_SLUG_PRIVACY = 'privacy_policy';
export const CMS_SLUG_KYC = 'kyc';
export const CMS_SLUG_WHY_US = 'why_us';
export const CMS_SLUG_ONLINE_ROULETTE = 'online_roulette';
export const CMS_SLUG_FREE_CASINO_GAMES = 'free_casino_games';
export const CMS_SLUG_FREE_SLOTS = 'free_slot_machines';
export const CMS_SLUG_SELF_EXCLUSION = 'self_exclusion';
export const CMS_SLUG_DISPUTE_RESOLUTION = 'dispute_resolution';
export const CMS_SLUG_ANTI_MONEY_LAUNDERING = 'anti_money_laundering';
export const CMS_SLUG_FAIRNESS_RNG_TESTING_METHODS = 'fairness_rng_testing_methods';
export const CMS_SLUG_PAY_OUTS_BONUSES = 'pay_outs_bonuses';
export const CMS_SLUG_PROMOTION_LOGIN = 'promotion_login_modal';
export const GAME_MODE_DEMO = 'demo';
export const GAME_MODE_REAL = 'real';
export const GAMES_SORT_KEY_DEFAULT = 'yk';
export const GAMES_SORT_KEY_MOST_RECENT = 'most_recent';
export const GAMES_SORT_KEY_OLDEST = 'oldest';
export const GAMES_SORT_KEY_AZ = 'az';
export const GAMES_SORT_KEY_ZA = 'za';
export const GAMES_SORT_KEY_VOLATILITY_HIGH = 'volatility_high';
export const GAMES_VOLATILITY_DEFAULT = 'default';
export const GAMES_BONUS_BET_ALLOWED = 'allowed';
export const GAMES_SORT_KEY_BET_LIMITS_LOW = 'bet_low';
export const GAMES_SORT_KEY_BET_LIMITS_HIGH = 'bet_high';
export const GAMES_SORT_KEY_VOLATILITY_LOW = 'volatility_low';
export const GAMES_SORT_KEY_PAYOUT_HIGH = 'payout_high';
export const GAMES_SORT_KEY_PAYOUT_LOW = 'payout_low';
export const GAMES_FILTER_GROUP_VOLATILITY = 'volatility';
export const GAMES_FILTER_GROUP_BONUS_BET = 'bonus_bet';
export const GAMES_FILTER_GROUP_PROVIDERS = 'providers';
export const BONUS_STATUS_CLAIMED = 'claimed';
export const BONUS_TYPE_DEPOSIT = 'deposit';
export const BONUS_TYPE_FREE = 'free';
export const BONUS_TYPE_FREE_ROUNDS = 'free_rounds';
export const BONUS_CLAIMING_EVENT_DEPOSIT = 'deposit';
export const BONUS_MODEL_DIRECT = 'direct';
export const BONUS_MODEL_UPPER = 'upper';
export const BONUS_MODEL_WAGER = 'wager';
export const PAYMENT_METHOD_VISA = 'visa';
export const PAYMENT_METHOD_MASTERCARD = 'mastercard';
export const PAYMENT_METHOD_MAESTRO = 'maestro';
export const PAYMENT_METHOD_CASHLIB = 'cashlib';
export const PAYMENT_METHOD_CASHLIB_V2 = 'cashlibv2';
export const PAYMENT_METHOD_EZEEWALLET = 'ezeewallet';
export const PAYMENT_METHOD_FLEXEPIN = 'flexepin';
export const PAYMENT_METHOD_ONLINEBANKING = 'onlinebanking';
export const PAYMENT_METHOD_X1 = 'x1';
export const PAYMENT_METHOD_AMEX = 'amex';
export const PAYMENT_METHOD_AMERICAN_EXPRESS_LIBRARY = 'american-express';
export const PAYMENT_METHOD_DINERS = 'diners';
export const PAYMENT_METHOD_DINERS_LIBRARY = 'diners-club';
export const PAYMENT_METHOD_JCB = 'jcb';
export const PAYMENT_METHOD_TYPE_REUSED_CARD = 'reused_card';
export const PAYMENT_METHOD_SYNTHETIC_NEW_BANK_CARD = 'new_bank_card';
export const DEPOSIT_RESPONSE_TYPE_REDIRECT = 'redirection';
export const TRANSACTION_STATUS_FAILURE = 'failure';
export const TRANSACTION_STATUS_SUCCESS = 'success';
export const TRANSACTION_STATUS_CANCELED = 'canceled';
export const TRANSACTION_STATUS_PENDING = 'pending';
export const TRANSACTION_STATUS_ERROR = 'error';
export const TRANSACTION_STATUS_FRAUD = 'fraud';
export const TRANSACTION_STATUS_REFUSED = 'refused';
export const TRANSACTION_STATUS_TIMEOUT = 'timeout';
export const TRANSACTION_STATUS_NOT_FOUND = 'not_found';
export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';
export const MISCELLANEOUS = 'miscellaneous';
export const CB_FRONT_TYPE = 'bank_card_front_side';
export const CB_BACK_TYPE = 'bank_card_reverse_side';
export const PROOF_OF_RESIDENCE_TYPE = 'proof_of_residence';
export const BANK_ACCOUNT_IDENTITY_TYPE = 'bank_account_identity';
export const PROOF_OF_PAYMENT_METHOD_TYPE = 'proof_of_payment_method';
export const IDENTITY_DOCUMENT_TYPE = 'identity_document';
export const IDENTITY_DOCUMENT_REVERSE_SIDE_TYPE = 'identity_document_reverse_side';
export const RECENT_GAMES_SLUG = 'recent_games';
export const EDITOR_CHOICE_SLUG = 'slot_machines';
export const TRENDING_GAMES_SLUG = 'trending_games_lobby';
export const RECOMMENDED_GAMES_SLUG = 'recommended_games_lobby';
export const LIVE_GAMES_PLAYLIST_SLUG = 'live-games';
export const GLOBAL_ERROR_MESSAGE_KEY = 'globalErrorMessage';
export const GAME_CATEGORY_SLOTS = 'Slots';
export const GAME_CATEGORY_TABLE = 'Table Games';
export const GAME_CATEGORY_OTHER = 'Other';
export const GAME_CATEGORY_LIVE = 'Live Dealers';
export const PAYOUT_METHOD_BANK_TRANSFER = 'bank_transfer_manual';
export const PAYOUT_METHOD_BANK_TRANSFER_AUTO = 'bank_transfer_auto';
export const PAYOUT_METHOD_INTERAC = 'interac';
export const PAYOUT_METHOD_EZEEWALLET = 'ezeewallet';
export const PAYOUT_METHOD_PAYZ = 'payz';
export const PAYOUT_METHOD_JETON = 'jeton';
export const PAYOUT_METHOD_CRYPTO_MANUAL = 'crypto_manual';
export const LIVE_LOBBY = 'live_lobby';
export const NAV_GAMES_ALL = 'all_games';
export const NAV_GAMES_FAVORITE = 'favorite_games';
export const NAV_GAMES_SLOT_MACHINES = 'slots';
export const NAV_GAMES_TABLE = 'table_games';
export const NAV_GAMES_OTHER = 'other';
export const FILTER_LIVE_MOST_POPULAR = 'mostPopularGames';
export const FILTERS_LIVE_ALL = 'all-games';
export const FILTERS_LIVE_FAVORITE = 'favorite';
export const FILTERS_LIVE_BACCARAT = 'baccarat';
export const FILTERS_LIVE_BLACKJACK = 'blackjack';
export const FILTERS_LIVE_GAME_SHOWS = 'gameShows';
export const FILTERS_LIVE_POKER = 'poker';
export const FILTERS_LIVE_ROULETTE = 'roulette';
export const FILTERS_LIVE_SIC_BO = 'sic-bo';
export const LIVE_DEALER_STATUS_OPEN = 'open';
export const PROD_HOSTNAME = 'https://www.yokikana.com';
export const PAYOUT_STATUS_PENDING = 'pending';
export const PAYOUT_STATUS_ERROR = 'error';
export const PAYOUT_STATUS_REQUESTED = 'requested';
export const PAYOUT_STATUS_VALIDATED = 'validated';
export const PAYOUT_STATUS_PROCESSING = 'processing';
export const GAME_ROUTER_PATHNAME = '/[categoryName]/[providerName]/[gameName]';
export const YK_IS_LOADING = 'yk-is-loading';
export const DEFAULT_THUMB_PNG = '/thumbnail_default_yokikana.png';
export const DEFAULT_THUMB_WEBP = '/thumbnail_default_yokikana.webp';
export const AMUSNET_PROVIDER_SLUG = 'amusnet';
export const GAMEART_PROVIDER_SLUG = 'gameart';
export const ELK_STUDIO_PROVIDER_SLUG = 'elkstudios';
export const SKYWIND_PROVIDER_SLUG = 'skywind';
export const QUICKSPIN_PROVIDER_SLUG = 'quickspin';
export const NOLIMITCITY_PROVIDER_SLUG = 'nolimit-city';
export const PUSHGAMING_PROVIDER_SLUG = 'pushgaming';
export const TOMHORNGAMING_PROVIDER_SLUG = 'tomhorngaming';
export const PRAGMATIC_PLAY_SLUG = 'pragmaticplaylive';
export const PLAYNGO_PROVIDER_SLUG = 'play-n-go';
export const EVOLUTION_PROVIDER_SLUG = 'evolution';
export const NETENT_PROVIDER_SLUG = 'netent';
export const EXTENDED_NETENT_PROVIDER_SLUG = 'extendednetent';
export const REDTIGER_PROVIDER_SLUG = 'redtiger';
export const EZUGI_PROVIDER_SLUG = 'ezugi';
export const THUNDERKICK_PROVIDER_SLUG = 'thunderkick';
export const GAME_PLAY = 'gamePlay';
export const DESC = 'desc';
export const ASC = 'asc';
export const BAD_REQUEST = 'Bad request';
export const TRANSACTION_TYPE_WITHDRAW = 'withdraw_request';
export const DATADOG_SERVICE_NAME = 'yokikana-rum';
export const CLAIM_BONUSES = 'bonus/claim-bonuses';
export const REGISTER_REQUEST = 'player/register';
export const GUIDE_CASINO = 'Guide Casino';
export const LOCAL_STORAGE_REALITY_CHECK_DATE = 'realityCheckDate';
export const LOCAL_STORAGE_REALITY_CHECK_SESSION_LENGTH = 'realityCheckSessionLength';
export const LOCAL_STORAGE_IS_DEP_SUCCESS = 'isDepSuccess';
export const NOTIFICATIONS_STATUS_SUCCESS = 'success';
export const NOTIFICATIONS_STATUS_ERROR = 'error';
export const DEPOSIT_SUBMISSION = 'Deposit_Submission';
export const MODAL_DEPOSIT = 'deposit';
export const DEPOSIT_START = 'deposit_start';
export const MODAL_STATUS_SUCCESS = 'success';
export const MODAL_STATUS_ERROR = 'error';
export const MODAL_STATUS_WARNING = 'warning';
export const MODAL_WITHDRAW = 'withdraw';
export const MODAL_WALLET_PREVIEW = 'wallet-preview';
export const MENU_LOGIN = 'login';
export const MENU_REGISTER = 'register';
export const MENU_NEW_PASSWORD = 'new-password';
export const MENU_FORGOT_PASSWORD = 'forgot-password';
export const MENU_GENERAL = 'general';
export const PENDING_TIME_INTERVAL_0 = 0;
export const PENDING_TIME_INTERVAL_1 = 3000;
export const PENDING_TIME_INTERVAL_2 = 10000;
export const PENDING_TIME_INTERVAL_3 = 30000;
export const PENDING_TIME_INTERVAL_4 = 180000;
export const TRANSACTIONS_LOADING_TYPE_GLOBAL = 'global';
export const TRANSACTIONS_LOADING_TYPE_BUTTON = 'button';
export const TRANSACTION_ID = 'transactionId';
export const SUBCATEGORY_DISPLAY_ON_CASINO = 'display_on_casino';
export const SUBCATEGORY_DISPLAY_ON_LOBBY = 'display_on_lobby';
export const SUBCATEGORY_KEY_GAMES = 'games';
export const SUBCATEGORY_KEY_UNLOGGED_GAMES = 'unlogged_games';
export const GAME_PROVIDERS_MARQUEE_SLUG = 'game_providers_marquee';
export const ONTARIO_COUNTY = 'Ontario';
export const FREE_ROUNDS = 'free_rounds';
export const CB_SLUG_DEPOSIT_METHODS_ORDER = 'deposit_methods_order';
export const ONE_SECOND = 1000;
export const MINUTE_IN_SECONDS = 60000;
export const HAS_USER_LOGGED_IN = 'hasUserLoggedIn';
export const PRE_FOOTER_HOME_SLUG = 'pre_footer_home';
export const PRE_FOOTER_CASINO_GAMES_SLUG = 'pre_footer_casino_games';
export const PRE_FOOTER_SLOT_MACHINES_SLUG = 'pre_footer_slot_machines';
export const PRE_FOOTER_LIVE_CASINO_SLUG = 'pre_footer_live_casino';
export const PRE_FOOTER_ROULETTE_SLUG = 'pre_footer_roulette';
export const PRE_FOOTER_BLACKJACK_SLUG = 'pre_footer_blackjack';
export const LOBBY_ANIMATED_THUMBNAIL_SLUG = 'lobby_animated_thumbnail';
export const HIDDEN_TEXT = '###HiddenText###';
export const TOKEN_EXPIRED = 'player_temporary_token_expired';
export const TOKEN_INVALID = 'invalid_credentials';
export const THUMB_ANIMATION_TYPE_ZOOM = 'zoom';
export const LOGIN_ATTEMPTS_REACHED = 'login_attempt_limit_reached';
export const PUSH_NOTIFICATION_STATUS_READ = 'read';
export const PUSH_NOTIFICATION_STATUS_UNREAD = 'unread';
export const PUSH_NOTIFICATION_TYPE_ANNOUNCEMENT = 'Announcement';
export const NULL = 'null';
export const PUSH_NOTIFICATION_CTA_LINK = 'cta_link';
export const PUSH_NOTIFICATION_CTA_TEXT = 'cta_text';
export const PUSH_NOTIFICATION_ICON_URL = 'icon_url';
export const WALLET_TAB_TRANSACTIONS = 'transactions';
export const WALLET_TAB_GAMES_HISTORY = 'games_history';
export const GAME_ROUNDS_LOADING_TYPE_GLOBAL = 'global';
export const GAME_ROUNDS_LOADING_TYPE_BUTTON = 'button';
export const PROMO_STATUS_UPCOMING = 'upcoming';
export const PROMO_STATUS_ONGOING = 'ongoing';
export const PROMO_STATUS_EXPIRED = 'expired';
export const TOTP_CODE_INVALID = 'TOTP code invalid';
export const TOTP_NOT_VALID = 'TOTP is not valid.';
export const ENABLE = 'enable';
export const DISABLE = 'disable';
export const LOBBY = 'lobby';
export const PAGE = 'page';
export const HELP_CENTER_PAGE = 'help_center_page';
export const HELP_CENTER_LOBBY = 'help_center_lobby';
export const DOCUMENT_STATUS_VALIDATED = 'validated';
export const DOCUMENT_STATUS_TO_VALIDATE = 'to_validate';
export const DOCUMENT_STATUS_TO_UPLOAD = 'to_upload';
export const DOCUMENT_STATUS_OTHER = 'other';
export const DEPOSIT_VIEW_PENDING_WITHDRAWALS = 'deposit_view_pending_withdrawals';
export const DEPOSIT_VIEW_BONUS = 'deposit_view_bonus';
export const DEPOSIT_VIEW_PAYMENT_METHOD = 'deposit_view_payment_method';
export const DEPOSIT_VIEW_X1_INFORMATION = 'deposit_view_x1_information';
export const BANNER_TYPE_DEFAULT = 'TYPE_DEFAULT';
export const BANNER_TYPE_OFFER = 'TYPE_OFFER';
export const PLAYER_FIRST_NAME_ROMANJI = 'first_name_romaji';
export const PLAYER_LAST_NAME_ROMANJI = 'last_name_romaji';
