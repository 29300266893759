const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
            d="M0.41879 0.418513C0.92634 -0.0888499 1.72049 -0.134896 2.28007 0.28032L2.44038 0.418724L6.0002 3.97872L9.55687 0.42262C10.1151 -0.135537 11.0202 -0.135537 11.5785 0.42262C12.086 0.930037 12.1321 1.72406 11.7169 2.28359L11.5785 2.44389L8.02158 6.0002L11.5814 9.56022C12.1396 10.1184 12.1395 11.0234 11.5812 11.5815C11.0737 12.0889 10.2795 12.1349 9.71993 11.7197L9.55962 11.5813L5.99999 8.02147L2.44504 11.5759C1.88679 12.134 0.981696 12.134 0.42345 11.5759C-0.0840466 11.0684 -0.130183 10.2744 0.285041 9.71489L0.42345 9.55459L3.97861 5.99999L0.418578 2.43978C-0.139609 1.88156 -0.139514 0.976613 0.41879 0.418513Z"
            fill="#916D46"
        />
    </svg>
);

export default Cross;
