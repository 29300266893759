const getFormattedAmountDefault = (amountValue, limit) => amountValue.toFixed(limit).toLocaleString();

const getFormattedAmountJPY = (amountValue = 0) =>
    amountValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });

export const formatCurrency = (amount = 0, currency, limit = 2) => {
    const amountValue = parseFloat(String(amount).replace(/,/g, ''));

    switch (currency) {
    case 'JPY':
        return `¥${getFormattedAmountJPY(amountValue)}`;
    case 'EUR':
        return `${getFormattedAmountDefault(amountValue, limit)} €`;
    case 'CAD':
    case 'USD':
        return `$${getFormattedAmountDefault(amountValue, limit)}`;
    case 'INR':
        return `₹${getFormattedAmountDefault(amountValue, limit)}`;
    case null:
        return getFormattedAmountDefault(amountValue, limit);
    default:
        return `${currency} ${getFormattedAmountDefault(amountValue, limit)}`;
    }
};

export const formatCurrencySymbol = (currency) => {
    switch (currency) {
    case 'EUR':
        return '€';
    case 'CAD':
    case 'USD':
        return '$';
    case 'INR':
        return '₹';
    case 'JPY':
        return '¥';
    default:
        return currency;
    }
};

export const getAmountRange = (currency) => {
    const defaultRange = ['25', '50', '100', '200'];
    switch (currency) {
    case 'EUR':
    case 'CAD':
    case 'CHF':
        return defaultRange;
    case 'INR':
        return ['1000', '5000', '10000', '20000'];
    case 'JPY':
        return ['10000', '15000', '20000', '30000'];
    default:
        return defaultRange;
    }
};

export const formatBannerCurrency = (amount = 0, currency, limit = 0) => {
    const amountValue = parseFloat(String(amount).replace(/,/g, ''));

    switch (currency) {
    case 'CAD':
    case 'USD':
        return `$${getFormattedAmountDefault(amountValue, limit)}`;
    case 'CHF':
        return `${currency} ${getFormattedAmountDefault(amountValue, limit)}`;
    default:
        return `¥ ${getFormattedAmountJPY(amountValue)}`;
    }
};
