import cx from 'classnames';
import Modal from '../ui/Modal';
import Cross from '../svg-icons/Cross';
import { isMobile } from 'react-device-detect';

const StatusModalContainer = ({ isOpen, onClose, modalClass, title, children }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className={cx('modal--status', { [modalClass]: modalClass })}
            disableScrollLock={false}
            transitionEffect={isMobile ? 'slide' : 'fade'}
        >
            <div className="modal-box">
                {!isMobile && (
                    <div className="modal-box--title">
                        {title}
                        <button className="modal-box--close" data-testid="close_modal" onClick={onClose}>
                            <Cross />
                        </button>
                    </div>
                )}
                <div className="modal-content">{children}</div>
            </div>
        </Modal>
    );
};

export default StatusModalContainer;
