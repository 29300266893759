import { gamesWorkers } from '../../services/http';
import { setLoadingPercent } from './global';
import { getCategoryId, getProviderIdBySlug, getLoadingPercent, handleRequestError } from '@tlf-e/brand-utils';
import { setDomainCookie } from '../../utils/cookieConfig';
import { includeRouterContext } from '../../utils/locationConfig';
import has from 'lodash/has';
import {
    AMUSNET_PROVIDER_SLUG,
    COOKIE_NAME_TOKEN,
    ELK_STUDIO_PROVIDER_SLUG,
    GAME_CATEGORY_LIVE,
    GAMEART_PROVIDER_SLUG,
    SKYWIND_PROVIDER_SLUG,
    QUICKSPIN_PROVIDER_SLUG,
    NOLIMITCITY_PROVIDER_SLUG,
    PUSHGAMING_PROVIDER_SLUG,
    TOMHORNGAMING_PROVIDER_SLUG,
    PLAYNGO_PROVIDER_SLUG,
    EVOLUTION_PROVIDER_SLUG,
    GAMES_VOLATILITY_DEFAULT,
    NETENT_PROVIDER_SLUG,
    EXTENDED_NETENT_PROVIDER_SLUG,
    REDTIGER_PROVIDER_SLUG,
    EZUGI_PROVIDER_SLUG,
    GAME_ROUNDS_LOADING_TYPE_GLOBAL,
    GAME_ROUNDS_LOADING_TYPE_BUTTON,
    PRAGMATIC_PLAY_SLUG,
    THUNDERKICK_PROVIDER_SLUG
} from '../../variables';

const { gamesRequest, getGameUrlRequest, favoriteRequest, latestWinnersRequest, getGameRounds } = gamesWorkers;

const GET_GAMES_DATA = 'GET_GAMES_DATA';
const GET_GAMES_DATA_SUCCESS = 'GET_GAMES_DATA_SUCCESS';
const GET_GAMES_DATA_FAILED = 'GET_GAMES_DATA_FAILED';
const GET_GAME_URL = 'GET_GAME_URL';
const GET_GAME_URL_SUCCESS = 'GET_GAME_URL_SUCCESS';
const GET_FAVORITE_GAMES = 'GET_FAVORITE_GAMES';
const GET_FAVORITE_GAMES_SUCCESS = 'GET_FAVORITE_GAMES_SUCCESS';
const GET_FAVORITE_GAMES_FAILED = 'GET_FAVORITE_GAMES_FAILED';
const CLEAN_GAME_URL = 'CLEAN_GAME_URL';
const SET_GAME_ROUTER_HISTORY = 'SET_GAME_ROUTER_HISTORY';
const SET_GAME_URL_AFTER_LOGIN = 'SET_GAME_URL_AFTER_LOGIN';
const CLEAN_GAME_LUNCH_SCRIPTS = 'CLEAN_GAME_LUNCH_SCRIPTS';
const GET_LATEST_WINNERS = 'GET_LATEST_WINNERS';
const GET_LATEST_WINNERS_SUCCESS = 'GET_LATEST_WINNERS_SUCCESS';
const GET_LATEST_WINNERS_FAILED = 'GET_LATEST_WINNERS_FAILED';
const SET_GAME_PREVIEW_GAME = 'SET_GAME_PREVIEW_GAME';
const TOGGLE_GAME_PREVIEW = 'TOGGLE_GAME_PREVIEW';
const SET_NAV_SCROLL_LEFT = 'SET_NAV_SCROLL_LEFT';
const SET_CASINO_ACTIVE_CATEGORY = 'SET_CASINO_ACTIVE_CATEGORY';
const GET_GAME_ROUNDS = 'GET_GAME_ROUNDS';
const GET_GAME_ROUNDS_SUCCESS = 'GET_GAME_ROUNDS_SUCCESS';
const GET_GAME_ROUNDS_FAILED = 'GET_GAME_ROUNDS_FAILED';
const INIT_GAME_LAUNCH_CONTENT = 'INIT_GAME_LAUNCH_CONTENT';

const restrictedProvidersSlug = [
    AMUSNET_PROVIDER_SLUG,
    GAMEART_PROVIDER_SLUG,
    ELK_STUDIO_PROVIDER_SLUG,
    SKYWIND_PROVIDER_SLUG,
    QUICKSPIN_PROVIDER_SLUG,
    NOLIMITCITY_PROVIDER_SLUG,
    PUSHGAMING_PROVIDER_SLUG,
    TOMHORNGAMING_PROVIDER_SLUG,
    THUNDERKICK_PROVIDER_SLUG
];

const acceptedProviderSlugs = [
    EVOLUTION_PROVIDER_SLUG,
    NETENT_PROVIDER_SLUG,
    EXTENDED_NETENT_PROVIDER_SLUG,
    REDTIGER_PROVIDER_SLUG,
    EZUGI_PROVIDER_SLUG
];

const replaceSpecialCharacters = (str) => str.replace(/\\u0026/g, '&');

const extendGame = (game, isDemoRestricted) => ({
    ...game,
    name: replaceSpecialCharacters(game.name),
    slug: replaceSpecialCharacters(game.slug),
    metadata: {
        ...game.metadata,
        bonusBetAllowed: !has(game.metadata, 'bonusBetAllowed'), //if response doesn't contain bonusBetAllowed it means originally true value
        volatility: game.metadata.volatility || GAMES_VOLATILITY_DEFAULT,
        rtp: game.metadata.rtp || 0
    },
    isDemoRestricted,
    ratio: game.ratio || 1.77
});

export const initialState = {
    data: {
        games: [],
        softwares: [],
        categories: []
    },
    gameRouterHistory: null,
    isGamesLoading: false,
    favoriteGamesLoading: false,
    favoriteGames: [],
    isGameUrlLoading: false,
    gameUrl: '',
    gameLaunchScripts: [],
    gameLaunchContent: '',
    gameUrlAfterLogin: '',
    latestWinners: [],
    latestWinnersLoading: false,
    isGamePreviewOpened: false,
    gamePreviewGame: {},
    navScrollLeft: 0,
    casinoActiveCategory: '',
    gameRounds: {
        loadingType: '',
        list: [],
        offset: 0,
        from: '',
        to: '',
        hideLoadMore: false
    }
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_GAMES_DATA:
        return {
            ...state,
            isGamesLoading: true
        };
    case GET_GAMES_DATA_SUCCESS:
        const restrictedProvidersSlugId = action.payload.data.softwares
            .filter((item) => restrictedProvidersSlug.includes(item.slug))
            .map((item) => item.id);
        const games = action.payload.data.games.reduce((acc, game) => {
            game.isLive = game.categoryId === getCategoryId(action.payload.data.categories, GAME_CATEGORY_LIVE);
            game.isEvolution =
                    game.softwareId === getProviderIdBySlug(action.payload.data.softwares, EVOLUTION_PROVIDER_SLUG);

            const isLivePragmatic =
                    game.isLive &&
                    game.softwareId === getProviderIdBySlug(action.payload.data.softwares, PRAGMATIC_PLAY_SLUG);

            return game.isLive
                ? game.liveDealers?.length
                    ? [
                        ...acc,
                        ...game.liveDealers.map((live) => ({
                            ...live,
                            ...extendGame(game, true),
                            liveDealers: live,
                            name: live.name,
                            tableHash: live.id,
                            uniqueTableId: Number('' + game.id + live.id)
                        }))
                    ]
                    : isLivePragmatic
                        ? [
                            ...acc,
                            {
                                ...extendGame(game, true),
                                status: 'open',
                                uniqueTableId: game.id,
                                thumbnail: game.metadata.thumbnailBackground
                            }
                        ]
                        : [...acc]
                : [
                    ...acc,
                    {
                        ...extendGame(game, restrictedProvidersSlugId.includes(game.softwareId))
                    }
                ];
        }, []);
        return {
            ...state,
            data: {
                ...action.payload.data,
                games
            },
            isGamesLoading: false
        };
    case GET_GAMES_DATA_FAILED:
        return {
            ...state,
            isGamesLoading: false
        };
    case GET_GAME_URL:
        return {
            ...state,
            isGameUrlLoading: true
        };
    case GET_GAME_URL_SUCCESS:
        const responseData = action.payload.data;
        const gameData = state.data.games.find((game) => game.id === action.id);
        const gameUrl = Object.values(responseData.data)[0];
        const providerSlug = state.data.softwares.find((software) => software.id === gameData.softwareId)?.slug;
        const isEvolutionProvider = acceptedProviderSlugs.includes(providerSlug);
        let gameLaunchScripts = [];
        if (isEvolutionProvider) {
            const regex = /<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g;
            gameLaunchScripts = responseData.data?.content?.match(regex) || [];
        }

        let gameLaunchContent = '';
        if ([PLAYNGO_PROVIDER_SLUG, TOMHORNGAMING_PROVIDER_SLUG].includes(providerSlug)) {
            gameLaunchContent = responseData.data?.content || '';
        }

        return {
            ...state,
            isGameUrlLoading: false,
            gameUrl,
            gameLaunchScripts,
            gameLaunchContent
        };
    case CLEAN_GAME_URL:
        return {
            ...state,
            gameUrl: ''
        };
    case GET_FAVORITE_GAMES:
        return {
            ...state,
            favoriteGamesLoading: true
        };
    case GET_FAVORITE_GAMES_SUCCESS:
        const favoriteGamesData = Object.values(action.payload.data);
        return {
            ...state,
            favoriteGamesLoading: false,
            favoriteGames: favoriteGamesData
        };
    case GET_FAVORITE_GAMES_FAILED:
        return {
            ...state,
            favoriteGamesLoading: false
        };
    case SET_GAME_ROUTER_HISTORY:
        return {
            ...state,
            gameRouterHistory: action.payload
        };
    case SET_GAME_URL_AFTER_LOGIN:
        return {
            ...state,
            gameUrlAfterLogin: action.payload
        };
    case CLEAN_GAME_LUNCH_SCRIPTS:
        return {
            ...state,
            gameLaunchScripts: []
        };
    case GET_LATEST_WINNERS:
        return {
            ...state,
            latestWinnersLoading: true
        };
    case GET_LATEST_WINNERS_SUCCESS:
        return {
            ...state,
            latestWinners: action.payload.data.bigWinner,
            latestWinnersLoading: false
        };
    case GET_LATEST_WINNERS_FAILED:
        return {
            ...state,
            latestWinnersLoading: false
        };
    case TOGGLE_GAME_PREVIEW:
        return {
            ...state,
            isGamePreviewOpened: action.payload
        };
    case SET_GAME_PREVIEW_GAME:
        return {
            ...state,
            gamePreviewGame: action.payload
        };
    case SET_NAV_SCROLL_LEFT:
        return {
            ...state,
            navScrollLeft: action.payload
        };
    case SET_CASINO_ACTIVE_CATEGORY:
        return {
            ...state,
            casinoActiveCategory: action.payload
        };
    case GET_GAME_ROUNDS_SUCCESS:
        return {
            ...state,
            gameRounds: {
                ...action.payload,
                loadingType: ''
            }
        };
    case GET_GAME_ROUNDS:
        return {
            ...state,
            gameRounds: {
                ...state.gameRounds,
                loadingType: action.payload
            }
        };
    case GET_GAME_ROUNDS_FAILED:
        return {
            ...state,
            gameRounds: {
                ...state.gameRounds,
                loadingType: ''
            }
        };
    case INIT_GAME_LAUNCH_CONTENT:
        return {
            ...state,
            gameLaunchContent: ''
        };
    default:
        return state;
    }
};

export const getGamesData = (device, withLoadingPercent = true) => {
    const loading = () => ({
        type: GET_GAMES_DATA
    });
    const success = (payload) => ({
        type: GET_GAMES_DATA_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_GAMES_DATA_FAILED
    });

    return (dispatch) => {
        dispatch(loading());
        gamesRequest(device)
            .then((res) => {
                withLoadingPercent &&
                    dispatch(setLoadingPercent(getLoadingPercent(!!res.config.headers['Authorization'], 'games')));
                dispatch(success(res.data));
            })
            .catch((err) => {
                if (err.status !== 401) {
                    includeRouterContext(handleRequestError, err);
                    fail();
                } else {
                    dispatch(getGamesData(device));
                }
            });
    };
};

export const cleanGameUrl = () => ({
    type: CLEAN_GAME_URL
});

export const getGameUrl = (lang, id, body) => {
    const loading = () => ({
        type: GET_GAME_URL
    });
    const success = (payload) => ({
        type: GET_GAME_URL_SUCCESS,
        payload,
        id
    });
    return (dispatch) => {
        dispatch(loading());
        getGameUrlRequest(lang, id, body)
            .then((res) => dispatch(success(res, id)))
            .catch((err) => includeRouterContext(handleRequestError, err));
    };
};

export const getFavoriteList = (id, method) => {
    const loading = () => ({
        type: GET_FAVORITE_GAMES
    });
    const success = (payload) => ({
        type: GET_FAVORITE_GAMES_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_FAVORITE_GAMES_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        favoriteRequest(id, method)
            .then((res) => {
                res.data.authorization && setDomainCookie(COOKIE_NAME_TOKEN, res.data.authorization.token);
                dispatch(success(res.data));
            })
            .catch(() => dispatch(fail()));
    };
};

export const setGameRouterHistory = (payload) => ({
    type: SET_GAME_ROUTER_HISTORY,
    payload
});

export const setGameUrlAfterLogin = (payload) => ({
    type: SET_GAME_URL_AFTER_LOGIN,
    payload
});

export const getLatestWinners = () => {
    const loading = () => ({
        type: GET_LATEST_WINNERS
    });
    const success = (payload) => ({
        type: GET_LATEST_WINNERS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_LATEST_WINNERS_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        latestWinnersRequest()
            .then((res) => dispatch(success(res.data)))
            .catch(() => dispatch(fail()));
    };
};
export const cleanGameLaunchScripts = () => ({
    type: CLEAN_GAME_LUNCH_SCRIPTS
});

export const setGamePreviewGame = (payload) => ({
    type: SET_GAME_PREVIEW_GAME,
    payload
});

export const toggleGamePreview = (payload) => ({
    type: TOGGLE_GAME_PREVIEW,
    payload
});

export const setNavScrollLeft = (payload) => ({
    type: SET_NAV_SCROLL_LEFT,
    payload
});

export const setCasinoActiveCategory = (payload) => ({
    type: SET_CASINO_ACTIVE_CATEGORY,
    payload
});

export const setGameRounds = (payload) => ({
    type: GET_GAME_ROUNDS_SUCCESS,
    payload
});

export const fetchGameRounds = (offset, from, to, limit) => {
    const loading = (payload) => ({
        type: GET_GAME_ROUNDS,
        payload
    });

    const fail = () => ({
        type: GET_GAME_ROUNDS_FAILED
    });
    return (dispatch, getState) => {
        const isInit = offset === 0;
        dispatch(loading(isInit ? GAME_ROUNDS_LOADING_TYPE_GLOBAL : GAME_ROUNDS_LOADING_TYPE_BUTTON));
        getGameRounds({
            offset,
            from,
            to,
            limit
        })
            .then((res) => {
                const { round } = res.data.data;
                dispatch(
                    setGameRounds({
                        list: isInit ? round : [...getState().games.gameRounds.list, ...round],
                        offset: offset + limit,
                        from,
                        to,
                        hideLoadMore: round.length < limit
                    })
                );
            })
            .catch(() => dispatch(fail()));
    };
};

export const initGameLaunchContent = () => ({
    type: INIT_GAME_LAUNCH_CONTENT
});
