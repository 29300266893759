import { bonusWorkers } from '../../services/http';
import { selectBonus } from './bank';
import { BONUS_CLAIMING_EVENT_DEPOSIT, BONUS_STATUS_CLAIMED, BONUS_TYPE_FREE } from '../../variables';

const { bonusListRequest, bonusesClaimRequest, bonusCancelRequest } = bonusWorkers;

const GET_BONUS_DATA = 'GET_BONUS_DATA';
const GET_BONUS_DATA_SUCCESS = 'GET_BONUS_DATA_SUCCESS';
const GET_BONUS_DATA_FAILED = 'GET_BONUS_DATA_FAILED';
const CLAIM_BONUS = 'CLAIM_BONUS';
const CLAIM_BONUS_SUCCESS = 'CLAIM_BONUS_SUCCESS';
const CLAIM_BONUS_FAILED = 'CLAIM_BONUS_FAILED';
const CLAIM_BONUS_INIT = 'CLAIM_BONUS_INIT';
const REMOVE_BONUS_SUCCESS = 'REMOVE_BONUS_SUCCESS';
const REMOVE_BONUS_FAIL = 'REMOVE_BONUS_FAIL';
const REMOVE_BONUS = 'REMOVE_BONUS';
const SET_MODAL_CANCEL_BONUS_ID = 'SET_MODAL_CANCEL_BONUS_ID';
const SET_FREE_ROUND_BONUS_ACTIVE = 'SET_FREE_ROUND_BONUS_ACTIVE';
const INIT_FREE_ROUND_BONUS_ACTIVE = 'INIT_FREE_ROUND_BONUS_ACTIVE';
const CLEAN_REMOVE_BONUS_ERROR = 'CLEAN_REMOVE_BONUS_ERROR';

export const initialState = {
    data: {
        allOfferedBonuses: [],
        offeredDeposit: [],
        allClaimedBonuses: []
    },
    depositClaimingEventGroups: null,
    isLoading: false,
    isLoaded: false,
    showPageLoader: false,
    claimBonusId: null,
    claimBonusMessage: '',
    claimBonusFailed: false,
    removeBonusLoadingId: null,
    removeBonusId: null,
    removeBonusFailed: false,
    removeBonusErrorMessage: '',
    modalCancelBonusId: null,
    isFreeRoundsBonusActive: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_BONUS_DATA:
        return {
            ...state,
            isLoading: true,
            showPageLoader: action.payload
        };
    case GET_BONUS_DATA_SUCCESS:
        return {
            ...state,
            data: action.payload,
            isLoading: false,
            showPageLoader: false,
            isLoaded: true
            // depositClaimingEventGroups: getOfferedClaimEventDeposit(list) //TODO When it's ready on backend - uncomment this line
        };
    case GET_BONUS_DATA_FAILED:
        return {
            ...state,
            isLoading: false,
            showPageLoader: false,
            isLoaded: true
        };
    case CLAIM_BONUS:
        return {
            ...state,
            claimBonusId: action.payload
        };
    case CLAIM_BONUS_SUCCESS:
        return {
            ...state,
            claimBonusId: null,
            claimBonusMessage: action.payload,
            claimBonusFailed: false
        };
    case CLAIM_BONUS_FAILED:
        return {
            ...state,
            claimBonusId: null,
            claimBonusMessage: action.payload,
            claimBonusFailed: true
        };
    case CLAIM_BONUS_INIT:
        return {
            ...state,
            claimBonusId: null,
            claimBonusMessage: '',
            claimBonusFailed: false
        };
    case REMOVE_BONUS:
        return {
            ...state,
            removeBonusId: action.payload
        };
    case REMOVE_BONUS_SUCCESS:
        return {
            ...state,
            removeBonusId: null,
            removeBonusFailed: false
        };
    case REMOVE_BONUS_FAIL:
        return {
            ...state,
            removeBonusFailed: true,
            removeBonusErrorMessage: action.payload.data
        };
    case SET_MODAL_CANCEL_BONUS_ID:
        return {
            ...state,
            modalCancelBonusId: action.payload
        };
    case SET_FREE_ROUND_BONUS_ACTIVE:
        return {
            ...state,
            isFreeRoundsBonusActive: true
        };
    case INIT_FREE_ROUND_BONUS_ACTIVE:
        return {
            ...state,
            isFreeRoundsBonusActive: false
        };
    case CLEAN_REMOVE_BONUS_ERROR:
        return {
            ...state,
            removeBonusId: null,
            removeBonusFailed: false,
            removeBonusErrorMessage: ''
        };
    default:
        return state;
    }
};

export const getBonusData = (showPageLoader = false, isPreselectedBonus = false) => {
    const loading = () => ({
        type: GET_BONUS_DATA,
        payload: showPageLoader
    });
    const success = (payload) => ({
        type: GET_BONUS_DATA_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_BONUS_DATA_FAILED
    });
    return (dispatch, getState) => {
        dispatch(loading());
        bonusListRequest()
            .then((res) => {
                const selectedBonus = getState().bank.selectedBonus;
                const list = res.data.data;
                const allOfferedBonuses = newFilterBonuses.getOfferedBonuses(list);
                const allClaimedBonuses = newFilterBonuses.getClaimedBonuses(list);
                const offeredDeposit = allOfferedBonuses.filter(
                    (bonus) => bonus.claimingEvent === BONUS_CLAIMING_EVENT_DEPOSIT
                );

                dispatch(
                    success({
                        allOfferedBonuses,
                        allClaimedBonuses,
                        offeredDeposit
                    })
                );
                isPreselectedBonus && !selectedBonus && dispatch(selectBonus(offeredDeposit[0]?.id || ''));
            })
            .catch(() => dispatch(fail()));
    };
};

export const claimBonus = (id, gameRedirect) => {
    const loading = (payload) => ({
        type: CLAIM_BONUS,
        payload
    });
    const success = (payload) => ({
        type: CLAIM_BONUS_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: CLAIM_BONUS_FAILED,
        payload
    });
    return (dispatch, getState) => {
        dispatch(loading(id));
        bonusesClaimRequest({ bonusIds: [id] })
            .then((res) => {
                const message =
                    res.data.data.type === BONUS_TYPE_FREE ? getState().global.data.translations['rewards.open_bonus'] : '';
                dispatch(success(message));
                dispatch(getBonusData(true));
                gameRedirect && gameRedirect();
            })
            .catch((err) => dispatch(fail(JSON.stringify(err.data))));
    };
};

export const removeBonusAction = (id) => {
    const loading = (payload) => ({
        type: REMOVE_BONUS,
        payload
    });
    const success = () => ({
        type: REMOVE_BONUS_SUCCESS
    });
    const fail = (payload) => ({
        type: REMOVE_BONUS_FAIL,
        payload
    });
    return (dispatch) => {
        dispatch(loading(id));
        bonusCancelRequest(id)
            .then(() => {
                dispatch(success());
                dispatch(getBonusData(true));
            })
            .catch((err) => dispatch(fail(err)));
    };
};

export const initClaimBonus = () => ({
    type: CLAIM_BONUS_INIT
});

export const setModalCancelBonusId = (payload) => ({
    type: SET_MODAL_CANCEL_BONUS_ID,
    payload
});

const newFilterBonuses = {
    getOfferedBonuses: (list) => list.filter((item) => item.status !== BONUS_STATUS_CLAIMED),
    getClaimedBonuses: (list) => list.filter((item) => item.status === BONUS_STATUS_CLAIMED)
};

// eslint-disable-next-line no-unused-vars
const getOfferedClaimEventDeposit = (list) =>
    list
        .filter((item) => item.status !== BONUS_STATUS_CLAIMED && item.claimingEvent === BONUS_CLAIMING_EVENT_DEPOSIT)
        .reduce((acc, item, index) => {
            const bonuses = item.promotionName
                ? {
                    [item.promotionName]: acc[item.promotionName] ? [...acc[item.promotionName], item] : [item]
                }
                : {
                    [`no_promotion_${index}`]: [item]
                };
            return {
                ...acc,
                ...bonuses
            };
        }, {});

export const setFreeRoundsBonusActive = () => ({
    type: SET_FREE_ROUND_BONUS_ACTIVE
});

export const resetFreeRoundsBonusActive = () => ({
    type: INIT_FREE_ROUND_BONUS_ACTIVE
});

export const cleanRemoveBonusError = () => ({
    type: CLEAN_REMOVE_BONUS_ERROR
});
