import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SEON_SCRIPTS = ['https://cdn.seondf.com/js/v5/agent.js'];

export const useSetupSeon = () => {
    const [loadSeonError, setLoadSeonError] = useState(false);
    const [configureSeonError, setConfigureSeonError] = useState(false);
    useEffect(() => {
        if (window.seon) return; // if seon is already loaded, do nothing
        const fetchAndExecuteScript = async (url) => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    setLoadSeonError(true);
                    return;
                }
                const scriptText = await response.text();
                const script = document.createElement('script');
                script.textContent = scriptText;
                document.body.appendChild(script);
            } catch (error) {
                setLoadSeonError(true);
            }
        };

        const loadingPromises = SEON_SCRIPTS.map((url) => fetchAndExecuteScript(url));

        Promise.all(loadingPromises)
            .then(() => {
                window.seon.config({
                    session_id: uuidv4(),
                    onSuccess: () => {},
                    onError: () => {
                        setConfigureSeonError(true);
                    }
                });
            })
            .catch(() => {
                setConfigureSeonError(true);
            });
    }, []);

    return { loadSeonError, configureSeonError };
};
