import dayjs from 'dayjs';
import { PROMO_STATUS_EXPIRED, PROMO_STATUS_UPCOMING, PROMO_STATUS_ONGOING } from '../variables';

export const getTime = (countdown) => {
    const totalSeconds = countdown.asSeconds();

    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return {
        days,
        hours,
        minutes,
        seconds
    };
};

export const setPromoStatus = (start, end) => {
    const now = dayjs.utc();
    let isPromoNotStarted = true;
    let isPromoFinished = true;

    if (start) {
        const startDate = dayjs.utc(start);
        const secondsUntilStart = Math.floor(startDate.diff(now) / 1000);
        isPromoNotStarted = secondsUntilStart > 0;

        if (isPromoNotStarted) {
            return {
                timerTimestamp: secondsUntilStart * 1000,
                status: PROMO_STATUS_UPCOMING
            };
        }
    }

    if (end) {
        const endDate = dayjs.utc(end);
        const secondsUntilEnd = Math.floor(endDate.diff(now) / 1000);
        isPromoFinished = secondsUntilEnd <= 0;
        const isPromoOngoing = !isPromoNotStarted && !isPromoFinished;

        if (isPromoOngoing) {
            return {
                timerTimestamp: secondsUntilEnd * 1000,
                status: PROMO_STATUS_ONGOING
            };
        }

        if (isPromoFinished) {
            return {
                timerTimestamp: null,
                status: PROMO_STATUS_EXPIRED
            };
        }
    }

    return {
        timerTimestamp: null,
        status: ''
    };
};

export const sanitizePromoData = (data) => (data !== 'null' ? data : '');
