import Link from 'next/link';
import { imageBaseUrl } from '../../services/http';
import routes from '../../utils/routes.json';

const LogoLink = ({ linkProps = {} }) => {
    return (
        <Link href={routes.home}>
            <a className="logo-container" data-testid="logo_container" {...linkProps}>
                <img src={`${imageBaseUrl}/main_logo.png`} alt="main_logo" />
            </a>
        </Link>
    );
};

export default LogoLink;
