import { useSelector } from 'react-redux';
import Notification from '../../../ui/Notification';
import NotificationTimer from '../../NotificationTimer';

const DocumentUploadNotification = ({ status, isOpen, onClose, picto, message }) => {
    const tr = useSelector((state) => state.global.data.translations);

    return (
        <Notification isOpen={isOpen} onClose={onClose} status={status} rootClass="transaction-snackbar" showTimer>
            {({ autoHideCountdown, onClose }) => (
                <NotificationTimer autoHideCountdown={autoHideCountdown} onClose={onClose}>
                    {({ timer }) => (
                        <div className="notification-content notification-content--timer">
                            <div>
                                {picto}
                                <span>
                                    <strong>{message}</strong>
                                </span>
                            </div>
                            {timer > 0 && (
                                <div className="notification-timer">
                                    {tr['pending_transaction.notification.countdown'].replace('##SECONDS##', timer)}
                                </div>
                            )}
                        </div>
                    )}
                </NotificationTimer>
            )}
        </Notification>
    );
};

export default DocumentUploadNotification;
