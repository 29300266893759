import { localeCountryCapitalized, shortenedLocale } from './locationConfig';

const getRouterLocale = (locale = '') => {
    const capitalizedLocale = localeCountryCapitalized(locale);
    const shortLocale = shortenedLocale(locale);

    return { capitalizedLocale, shortLocale };
};

export default getRouterLocale;
